<template lang="pug">
.main
    CompanyModal(ref="companyModal")
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu(:class="{shrink: shrink}")#submenu
            .container-xxl(v-if="$store.state.currentOrg && $store.state.currentOrg != {}")

                .row.no-gutters.submenu-inner.mt-0
                    .col-10
                        
                        .media
                            
                            Logo(:name="$store.state.currentOrg.name", :web_entity="$store.state.currentOrg.web_entity", :size="50", :rounded="false").d-none.d-lg-inline.mr-5
                            Logo(:name="$store.state.currentOrg.name", :web_entity="$store.state.currentOrg.web_entity", :size="25", :rounded="false").d-xs-inline-block.d-lg-none.mr-2
                            .media-body
                                .d-xs-inline-block.d-lg-none
                                
                                      a(:href="'/ecosystem/orgs/' + $store.state.currentOrg._id", target="_top")
                                        b#company-profile-head-small(style="white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis; max-width: 150px; display: inline-block") {{$store.state.currentOrg.name}} &nbsp;
                                .d-none.d-lg-block 
                                  
                                    
                                      .headline-submenu#company-profile-head {{$store.state.currentOrg.name}}
                                div(v-show="!shrink")
                                    
                                    div
                                      
                                        a(:href="(($store.state.currentOrg.homepage_url.indexOf('http') == 0) ? '' : '//')  + $store.state.currentOrg.homepage_url" target="_blank" v-if="$store.state.currentOrg.homepage_url")#company-profile-web_entity.mr-1.small {{$store.state.currentOrg.web_entity}} 
                                        a(:href="(($store.state.currentOrg.twitter_url.indexOf('http') == 0) ? '' : '//')  +$store.state.currentOrg.twitter_url" target="_blank" v-if="$store.state.currentOrg.twitter_url").mr-1  <i class="fab fa-twitter-square"></i>
                                        a(:href="(($store.state.currentOrg.facebook_url.indexOf('http') == 0) ? '' : '//')  +$store.state.currentOrg.facebook_url" target="_blank" v-if="$store.state.currentOrg.facebook_url").mr-1  <i class="fab fa-facebook-square"></i>
                                        a(:href="(($store.state.currentOrg.linkedin_url.indexOf('http') == 0) ? '' : '//')  +$store.state.currentOrg.linkedin_url" target="_blank" v-if="$store.state.currentOrg.linkedin_url").mr-1  <i class="fab fa-linkedin"></i>
                                    
                   
                    .col-2()
                        .float-right(v-if="$store.state.currentOrg._id")
                            b-form(inline)

                                //.d-none.d-lg-inline(v-if="$store.state.currentProjectOrganizations").mr-5
                                    b-input-group()
                                        ProjectStageSelect(id="projectStageAdd" v-model="selectedProjectStage", :create_option="false", :exclude_project_ids="$store.state.currentOrg.project_ids", style="min-width: 200px").small
                                        b-btn(size="sm",v-if="is_exclude(selectedProjectStage)" :to="'/ecosystem/orgs/'+$store.state.currentOrg._id+'/projects/' + selectedProjectStage" variant="outline-secondary", :disabled="!selectedProjectStage").small.ml-1 View
                                        b-btn(size="sm",v-if="!is_exclude(selectedProjectStage)" @click="addCompaniesToProject([$store.state.currentOrg], selectedProjectStage)" variant="outline-secondary", :disabled="!selectedProjectStage").small.ml-1 Add
                    
                                template(v-if="$store.state.currentOrg.relations && $store.state.currentOrg.relations.external") 
                                    .d-none.d-lg-inline
                                        b-btn(size="sm", variant="outline-primary", @click="showModal()")#add-ecosystem Add to ecosystem
                                    
                                    .d-xs-inline-block.d-lg-none
                                        b-btn(size="sm", variant="outline-primary", @click="showModal()")#add-ecosystem-small Add

                                template(v-else)
                                    .float-right()
                                        b-btn(size="sm", variant="outline-primary", @click="showModal()")#edit-company Edit
                                
                              
                //.row.no-gutters.submenu-inner
                    .col-md-12
                        .d-xs-inline-block.d-lg-none.mt-1(v-if="$store.state.currentOrg && $store.state.currentOrg != {}")
                            b-input-group()
                              
                                ProjectStageSelect(v-model="selectedProjectStage", style="min-width: 260px", :exclude_project_ids="$store.state.currentOrg.project_ids", :create_option="false").small
                                b-btn(size="sm",v-if="is_exclude(selectedProjectStage)" :to="'/ecosystem/orgs/'+$store.state.currentOrg._id+'/projects/' + selectedProjectStage" variant="outline-secondary", :disabled="!selectedProjectStage").small.ml-2 View
                                b-btn(size="sm",v-if="!is_exclude(selectedProjectStage)" @click="addCompaniesToProject([$store.state.currentOrg], selectedProjectStage)" variant="outline-secondary", :disabled="!selectedProjectStage").small.ml-2 Add
                
                .row.no-gutters.submenu-inner(v-if="$store.state.currentOrg && $store.state.currentOrg != {}")
                    
                    .col-md-12
                        .d-none.d-lg-block 
                            .menu.mt-2 

                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/insights'").mr-5 Overview
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/contacts'").mr-5 Contacts
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/sheet'").mr-5 Sheet
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/news'").mr-5 News
                            
                                //router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/website'").mr-5 Website
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/financials'").mr-5 Financials
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/funded_projects'", v-if="has_public_funding").mr-5 Funded Projects
                                //router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/website'").mr-5 Website
                                //router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/benchmark'").mr-5 Benchmark
                                //router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/surveys'").mr-5 Surveys
                           
                                
                                //router-link(to="projects").mr-5 Projects <span class="text-muted ml-1" v-if="$store.state.currentProjectOrganizations">{{$store.state.currentProjectOrganizations.length}}</span>
                                //router-link(to="evaluations").mr-5 Evaluations 
                                router-link(:to="'/ecosystem/orgs/'+$route.params.org_id+'/similar'" v-if="$store.state.currentOrg.web_entity").mr-5 Similar Companies <span class="text-muted ml-1"></span>
                              
                                template(v-for="ap in $store.state.api_configs") 
                                   
                                    template(v-if="!ap.required_fields || ap.required_fields.length == 0 || $store.state.currentOrg[ap.required_fields[0]]")
                                        router-link(:to="'/ecosystem/orgs/'+ $store.state.currentOrg._id +'/connect/' + ap._id").mr-5 
                                            
                                            | {{ap.name}}

                                //template(v-for="po in $store.state.currentProjectOrganizations")
                                
                               

                                //router-link(to="admin").d-none.d-lg-inline.mr-5 Admin <span class="text-muted ml-1"></span>
                
                    .col-md-12(v-if="$store.state.currentOrg && $store.state.currentOrg != {}")
                        .d-xs-inline-block.d-lg-none
                            .menu.m-0
                              div(style="font-size: 1.25em")
                                router-link(:to="'/ecosystem/orgs/'+ $route.params.org_id + '/insights'", alt="Overview").mr-4
                                  i.fas.fa-info-circle
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/contacts'").mr-4
                                  i.far.fa-id-card
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/sheet'").mr-4
                                  i.far.fa-file-alt
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/financials'").mr-4
                                  i.fas.fa-file-invoice-dollar
                                
                                router-link(:to="'/ecosystem/orgs/' + $route.params.org_id + '/funded_projects'", v-if="has_public_funding").mr-4
                                  i.fas.fa-file-invoice-dollar

                                router-link(:to="'/ecosystem/orgs/'+$route.params.org_id+'/similar'" v-if="$store.state.currentOrg.web_entity").mr-4
                                  i.fas.fa-network-wired
                                
                                template(v-for="po in  $store.state.currentProjectOrganizations")
                                
                                    router-link(:to="'/ecosystem/orgs/'+$store.state.currentOrg._id+'/projects/' + po.project_id") 
                                      
                                      Logo(:name="po.relations.project_id.name", :size="25",  :rounded="false") 
                                       
                                            
            
    .container-xxl(class="d-flex flex-column", v-if="$route.params.org_id && currentOrg")
          .row.no-gutters.work-view.sticky-container()
              
              b-col(:cols="sidebar_visible ? 9 : 12 ")
                #affix-container
                
                  router-view(name="main").main-view
              b-col(:cols="12-mainCols", v-if="mainCols < 12")
                  
                  .mt-3
                    .sidebar-right(v-bind:class="{'visible': sidebar_visible}", id="sideright", v-if="innerWidth > 800").col-3
                      .float-right
                        b-btn(variant="link", @click="toggleSidebar()", size="sm")
                          i.fas.fa-arrow-right(v-show="sidebar_visible")
                          i.fas.fa-arrow-left(v-show="!sidebar_visible")
                      router-view(name="sideview").side-view(v-show="sidebar_visible")
            
</template>

<script>
import Config from "@/config";
import Vue from "vue";

import Logo from '@/components/Logo'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import HSubmenuLink from '@/components/HSubmenuLink'
import CompanyModal from '@/components/CompanyModal.vue'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'SubmenuEcosystem',
  props: {
    title: String,
    
  },

  data: function () {
      return {
        innerWidth: window.innerWidth,
        selectAll: false,
        shrink: false,
        selectedProjectStage: null,
        mainCols: 9,
        filterCols: 2,
        has_public_funding: false
      }
  },

    watch: {
    '$route.params.org_id': function() {

      this.getData();
      
    },
    'currentOrg': function() {
     
      window.dispatchEvent(new Event('resize'));
      this.$eventHub.$emit('resize');
    }
  
  },


  beforeMount() {
    
    this.handleDebouncedScroll = this.$_.debounce(this.handleScroll, 10);
    window.addEventListener('scroll', this.handleDebouncedScroll );  
    window.addEventListener('resize', this.handleDebouncedScroll);  
  },


  beforeDestroy() {

      window.removeEventListener('scroll', this.handleDebouncedScroll);
      window.removeEventListener('resize', this.handleDebouncedScroll);  
  },

  computed: {

        ...mapFields([
            'instance_config',
            'sidebar_visible',
            'currentOrg'
        ]),
  },



  mounted: function() {
    
      this.$store.dispatch("loadOrg", {org_id: this.$route.params.org_id, web_entity: this.$route.query.web_entity});
       Vue.http
        .get(Config.config.base_host + "/organizations/" +this.$route.params.org_id +  "/public_funding")
        .then(resp => {
          
            this.has_public_funding = resp.body.length > 0;
          
        });

      window.dispatchEvent(new Event('resize'));
      this.$eventHub.$emit('resize');
  },

  methods: {
    
    toggleSidebar() {
      this.sidebar_visible = !this.sidebar_visible;
      this.$eventHub.$emit('resize');
    },

    handleScroll() {
      
      let el = document.getElementById("submenu");
     
      if(el) {
        let rect = el.getBoundingClientRect();
        let sel = document.getElementById("sideright")
        
        if(sel) {
          
          sel.style.top = rect.bottom +"px";
         
        }

         let searchsel = document.getElementById("searchmenu")
          if(searchsel) {
            searchsel.style.top = rect.bottom-30 +"px";
          }
        
      }
      
    },


    is_exclude(option) {

        if(!option) return false;
        if(!this.$store.state.currentOrg || !this.$store.state.currentOrg.project_ids) return false;   
        return this.$store.state.currentOrg.project_ids.map(x => x.project_id).indexOf(option) > -1;
    },

    addCompaniesToProject(selectedItem, selectedProjectStage) {
      let items = selectedItem.map(x => {
        return { organization_id: x._id, project_id: selectedProjectStage };
      });
      console.log(items, selectedProjectStage);
      this.$store.dispatch("start_loading");
      Vue.http
        .post(Config.config.base_host + "/project_organizations/bulk", items)
        .then(resp => {
          
          let data = resp.body;
          console.log(data);
          location.href = "/ecosystem/orgs/"+this.$route.params.org_id+"/projects/"+ data[0]._id.split("::")[0] + ((this.$route.query.extension) ? "?extension=1" : "") ;    

          this.$store.dispatch("stop_loading");
        });
    },

    showModal() {
        this.$refs.companyModal.open(this.$store.state.currentOrg);
    },
  

    select() {
   
        
        if(this.selectAll) {
            this.$store.dispatch('selectAll');
        } else {
            this.$store.dispatch('deselectAll');
        }
        
    },


    getData() {
        
        this.$store.dispatch("loadOrg", {org_id: this.$route.params.org_id, web_entity: this.$route.query.web_entity});

        Vue.http
        .get(Config.config.base_host + "/organizations/" +this.$route.params.org_id +  "/public_funding")
        .then(resp => {
          
            this.has_public_funding = resp.body.length > 0;
          
        });


    },


    addRecords: function() {

      this.$store.dispatch('addSelectedRecords');
      
    }
  },

  components: {
      Logo,
      CompanyModal,
      ProjectStageSelect,
      HSubmenuLink
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    
    @import '@/_vars.scss';
 
@media only screen and (max-width: 600px) {
 
}
</style>
