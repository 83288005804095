<template lang="pug">
  .search-filter
    b-form(autocomplete="off")
     
    

        .row.mt-3
       
          .col-9
            b-input-group(v-if="load_query_show")
              b-select(placeholder="Load query", v-model="load_query", text-field="name", value-field="_id" :options="saved_queries" @change="do_select_query(load_query)", size="sm")
                template(v-slot:first)
                  b-form-select-option(:value="null" disabled) Select a query to load
              b-btn(size="sm", variant="outline-secondary", v-if="load_query", @click="do_change_query(save_query, $store.state.query.filter, load_query)") Save

            b-input-group( v-if="save_query_show")
              b-input(placeholder="Query Name", v-model="save_query", size="sm")
              b-btn(size="sm", variant="outline-secondary", @click="do_save_query(save_query, $store.state.query.filter)", :disabled="!save_query") Save               
 
          .col-3 
            .float-right
              b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                template(v-slot:button-content)
                 
                  i.fas.fa-ellipsis-h
                
                b-dropdown-item(size="sm" @click="load_query=null;load_query_show=true;save_query_show=false") Load query
                b-dropdown-item(size="sm" @click="save_query=null;load_query_show=false;save_query_show=true") Save as new query
                //b-dropdown-divider()
                  b-dropdown-item(size="sm" @click="load_query_show!=true") Share query
        
        FilterGroup(title="Company Name" :collpased="true", v-if="search.includes('name_list')").mt-3
          .small
              v-select(id="orgname"
                      @input="getData()"
                      :multiple="true"
                      :taggable="true"
                      :close-on-select="false"
                      :no-drop="true"
                      :select-on-tab="true"
                      v-model="name_list"
                      placeholder="Enter a name and press enter", 
              )

        FilterGroup(title="Keywords" :collpased="true", v-if="search.includes('keywords')").mt-3
          
          .small
            
        
            b-input-group()
              TopicKeywordSelect(id="keyword", v-model="full_query", @search="getData()", :showRecommendations="false", :embed="false")
              
        
                  
          
          //template(#head_addition)
            template(v-if="use_query")
              b-link(@click="q=null; use_query=false").small Simple query
            template(v-else)
              b-link(@click="keywords=[]; use_query=true").small Advanced query
                

        FilterGroup(title="Similar to", v-if="search.includes('sim_search')", :collpased="true").mt-3
        
          //b-select(:options="['No similarity search', 'Company list']", v-model="selected", size="sm")#sim-search-select
          //b-textarea(placeholder="List of similar companies domains", v-model="domains",  v-show="selected=='Company list'" rows="3", max-rows="5", size="sm")#sim-search-ta.mt-2
          
          CompanyTextSelect(v-model="sim_search", @input="getData()")#sim-search-ta.small
          
        //FilterGroup(title="Projects").mt-3
            .small
              ProjectStageSelect(v-model="project_id", :create_option="false", :multiple="true", id="project_stage")
        
        FilterGroup(title="Categories" :collpased="true").mt-3
          template(v-if="instance_config.app_config.base.version == '354'")
            b-form-group(id="org_tags-group", label-for="org_tags", label-size="sm", v-for="field in project_config").h-mouseover-parent
                
                label() {{field.name}}
                .float-right
                  b-link(@click="custom_data[field.topic_type].push({'op': 'AND', 'q': [], 'key': getUUID()})", style="cursor: pointer")
                    label(style="cursor: pointer").text-gray <span class="h-mouseover-show">Advanced query</span> <i class="fa fa-plus"></i>
                
                div(v-for="(adv, i) in custom_data[field.topic_type]" :key="adv.key")
                  div(v-if="i == 0")
                    TaxonomySelect(id="stage"
                      :options="[]",
                      :topic_type="field.topic_type",
                      :multiple="true"
                      @input="getData()"
                      :select-on-tab="true"
                      v-model="adv.q").small
                
                div(v-for="(adv, i) in custom_data[field.topic_type]" :key="adv.key").mt-2
              
                  .form-row(v-if="i > 0")
                    .col-3
                      b-select(:options="['AND', 'NOT']", size="sm", v-model="adv.op", @input="getData()")
                    .col-8
                      TaxonomySelect(id="stage"
                                :options="[]",
                                :topic_type="field.topic_type",
                                :multiple="true"
                                @input="getData()"
                                :select-on-tab="true"
                                v-model="adv.q").small
                    .col-1()
                      b-btn(variant="link", size="sm", @click="deleteField(custom_data[field.topic_type], adv)").p-0.pt-1 <i class="fas fa-times" style="opacity: 0.3"></i>

          
          
          
          b-form-group(id="tags-group", label="Topics", label-for="topics", label-size="sm")
              TopicSelect(v-model="topic_ids", :create_option="false", :multiple="true", :edit="true", topic_type="topic", :need_query="true", @input="getData()", :show_follow_only="true").small


          b-form-group(id="tags-group", label="Tags", label-for="categories", label-size="sm")
              v-select(id="tags"
                        size="sm", 
                        :options="tags_options",
                        :multiple="true"
                        @input="getData()"
                        :close-on-select="false"
                        
                        :select-on-tab="true"
                        v-model="tags").small

          //b-form-group(id="tags-group", label="Categories", label-for="categories", label-size="sm")
            v-select(id="categories"
                      size="sm", 
                      :options="categories_options",
                      :multiple="true"
                      @input="getData()"
                      :close-on-select="false"
                      
                      :select-on-tab="true"
                      v-model="categories").small
          

          b-form-group(id="tags-group", label="Categories", label-for="categories", label-size="sm")
            v-select(id="categories"
                      size="sm", 
                      :options="crunchbase_category_list_options",
                      :multiple="true"
                      @input="getData()"
                      :close-on-select="false"
                      
                      :select-on-tab="true"
                      v-model="crunchbase_category_list").small


          b-form-group(id="tags-group", label="Lists", label-for="lists", label-size="sm")
              WatchlistSelect(id="lists"
                        size="sm", 
                        item_type="organization"
                        :multiple="true"
                        @input="getData()"
                        :close-on-select="false"
                        :select-on-tab="true"
                        v-model="watchlist_id").small

          b-form-group(id="tags-group", label="Projects", label-for="lists", label-size="sm")
            
            ProjectStageSelect(id="lists"
                      size="sm", 
                      placeholder=""
                      :multiple="true"
                      @input="getData()"
                      :close-on-select="false"
                      :select-on-tab="true"
                      v-model="project_id").small   

        FilterGroup(title="Basic Info" :collpased="true").mt-3

      
          
          b-form-group(id="input-group-founded_on", label="Foundation year", label-for="founded_on", label-size="sm")
              b-input-group(id="input-group-last_funding_on")
                b-input(type="number" placeholder="From", size="sm", v-model="founded_on_from", @change="getData()").mr-3
                div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                b-input(type="number" placeholder="To", size="sm", v-model="founded_on_to", @change="getData()").ml-3
          
       


          b-form-group(id="input-group-employee-number", label="Employee Number", label-for="employee-number", label-size="sm")
              
              v-select(id="employee-number"
                    @input="getData()"
                    :options="employee_count_options",
                    :multiple="true"
                    label="label"
                    :close-on-select="true"
                    :reduce="x => x"
                    :select-on-tab="true"
                    v-model="employee_count").small
                    template(slot="no-options") Type to search
                    template(slot="option" slot-scope="option")
                      | {{ option.label }}
          
          //b-form-group(id="input-group-company-status", label="Company Status", label-for="company-status", label-size="sm")
              
              v-select(id="company-status"
                    @input="getData()"
                    :options="['operating', 'closed']",
                    :multiple="true"
                    label="label"
                    :close-on-select="true"
                    :reduce="x => x.value"
                    :select-on-tab="true"
                    v-model="status").small
                    template(slot="no-options") Type to search
                    template(slot="option" slot-scope="option")
                      | {{ option.label }}

        FilterGroup(title="Locations" :collpased="true").mt-3
       
            b-form-group(v-if="country_code_options", id="input-group-country_code", label="Countries", label-for="input-2", label-size="sm")
               
                v-select(id="country_code"
                      @input="getData()"
                      :options="country_code_options",
                      :multiple="true"
                      label="label"
                      :close-on-select="true"
                      :reduce="x => x.value"
                      :select-on-tab="true"
                      v-model="country_code").small
                      template(slot="no-options") Type to search
                      template(slot="option" slot-scope="option")
                        | {{ option.label }}

            b-form-group(v-if="region_options", id="input-group-region", label="Regions", label-for="input-2", label-size="sm")
                v-select(id="region"
                      :options="region_options",
                      :multiple="true"
                      @input="getData()"
                      :close-on-select="true"
                      :select-on-tab="true"
                      v-model="region").small

            b-form-group(v-if="city_options", id="input-group-city", label="Cities", label-for="input-2", label-size="sm")
                v-select(id="city"
                      :options="city_options",
                      :multiple="true"
                      @input="getData()"
                      :filterable="true"
                      :close-on-select="true"
                      :select-on-tab="true"
                      v-model="city"
                      ).small

          

        FilterGroup(title="Financials" :collpased="true").mt-3

                //b-form-group(id="input-group-current_stage", label="Investors", label-for="input-2", label-size="sm")
                  v-select(id="city"
                        :options="current_funding_stage",
                        :multiple="true"
                        @input="getData()"
                        :filterable="true"
                        :close-on-select="true"
                        :select-on-tab="true"
                        v-model="city"
                        ).small

                b-form-group(id="input-group-revenue", label="Revenue", label-for="revenue", label-size="sm", v-if="instance_config.revenue_filter")
                  
                  b-input-group(id="input-group-last_funding_on")
                    b-input(type="number" placeholder="From", size="sm", v-model="current_revenue_usd_from", @change="getData()").mr-3
                    div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                    b-input(type="number" placeholder="To", size="sm", v-model="current_revenue_usd_to", @change="getData()").ml-3
              

                b-form-group(id="input-group-current_stage", label="Current Stage", label-for="input-2", label-size="sm")
                  v-select(id="city"
                        :options="last_funding_type_options",
                        :multiple="true"
                        @input="getData()"
                        :filterable="true"
                        :reduce="x => x.value"
                        :close-on-select="true"
                        :select-on-tab="true"
                        v-model="last_funding_type"
                        ).small

        FilterGroup(title="Sustainability" :collpased="true" , v-if="instance_config.sustainability_filter").mt-3  
                
                b-form-group(id="input-group-revenue", label="SBTI", label-for="sustainanility", label-size="sm")

                  v-select(id="sbti"
                        :options="sbti_options",
                        :multiple="true"
                        @input="getData()"
                        :filterable="true"
                        :reduce="x => x"
                        :close-on-select="true"
                        :select-on-tab="true"
                        v-model="sbti"
                        ).small

                b-form-group(id="input-group-revenue", label="CDP Score", label-for="sustainanility", label-size="sm")

                  v-select(id="cdp_score"
                        :options="cdp_score_options",
                        :multiple="true"
                        @input="getData()"
                        :filterable="true"
                        :reduce="x => x"
                        :close-on-select="true"
                        :select-on-tab="true"
                        v-model="cdp_score"
                        ).small




              

                
                //b-form-group(id="input-group-last_funding_on", label="Last funding", label-for="input-2", label-size="sm")
                  b-input-group(id="input-group-last_funding_on")
                    b-form-datepicker( :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }", placeholder="From").mr-3
                    div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                    b-form-datepicker(:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }", placeholder="To").ml-3
                
                //b-form-group(id="input-group-current_stage", label="Number funding rounds", label-for="input-2", label-size="sm")
                  vue-slider(v-model="number_of_funding_rounds", :data="['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','>15']")
                  //b-input(type="range" min="1" max="20" class="slider" id="myRange", style="background-color: transparent;")

                
                //b-form-group(id="input-group-founded_on", label="Number funding rounds", label-for="founded_on", label-size="sm")
                  b-input-group(id="input-group-last_funding_on")
                    b-input(type="number" placeholder="From", size="sm", v-model="number_of_funding_rounds[0]", @change="getData()").mr-3
                      div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                    b-input(type="number" placeholder="To", size="sm", v-model="number_of_funding_rounds[1]", @change="getData()").ml-3

                //b-form-group(id="input-group-founded_on", label="Total funding", label-for="founded_on", label-size="sm")
                  b-input-group(id="input-group-last_funding_on")
                    currency-input(type="number" placeholder="From", size="sm", v-model="total_funding_usd[0]", @change="getData()").mr-3
                      div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                    b-input(type="number" placeholder="To", size="sm", v-model="total_funding_usd[1]", @change="getData()").ml-3

        FilterGroup(title="Sources", :collpased="true").mt-3
            
            b-input-group()
              b-form-checkbox-group(@input="getData()", :options="sources_filter", v-model="sources", id="filter-group-sources" stacked)
            b-input-group()
              b-form-checkbox-group(@input="getData()", :options="info_filter", v-model="info", id="filter-group-info" stacked) 
            //b-input-group()
              b-form-checkbox-group(@input="getData()", :options="has_sustain", v-model="info", id="filter-group-info" stacked) 

        //FilterGroup(title="Add advanced filter" :collpased="true").mt-3

          .small Define additional advanced filters that will be connected to your filter criterias above.
          
          div(v-for="aq in additional_queries")
            
            b-form-group(id="input-group-city", :label="aq.field", label-for="input-2", label-size="sm")
              b-btn-group(id="")
               
                b-select(:options="['AND', 'OR', 'Exclude']", v-model="aq.op", size="sm")
               
              v-select(id="country_code", v-model="aq.value").small.mt-1
                      template(slot="no-options") Type to search
                      template(slot="option" slot-scope="option")

          b-input-group().mt-3
            b-select(:options="['Keywords', 'Catgeories > Industry', 'Catgeories > Enterprise Function', 'Catgeories > Vertical']", size="sm", v-model="add_filter")
            b-button(size="sm", variant="outline-secondary",@click="additional_queries.push({'op': 'AND', 'field': add_filter, 'value': null})") Add
            

        
        //.mt-3

          
              b-button(variant="outline-secondary", @click="getData()", size="sm")#ecosystem-search-button.form-control Search
              .float-right
                b-btn(variant="link") Save query
               
          
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'

import FilterGroup from '@/components/FilterGroup'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import CompanyTextSelect from '@/components/CompanyTextSelect'
import TaxonomySelect from '@/components/TaxonomySelect'
import WatchlistSelect from '@/components/WatchlistSelect'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import TopicSelect from '@/components/TopicSelect'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'SearchFilter',

  data: function () {
    return {
      sources_filter: [{"text": "My Ecosystem", "value": "ecosystem"}, {"text": "External sources", "value": "hy"}],
      project_config: [{"name": "Industry", "topic_type": "industry", "adv": []},{"name": "Enterprise Function", "topic_type": "enterprise_function", "adv":  []},{"name": "Vertical",  "topic_type": "vertical", "adv":  []}, {"name": "Use case",  "topic_type": "use_case", "adv":  []}],
      info_filter: [{"text": "German registry startups only", "value": "is_startup"}],
      keyword_options: [],
      use_query: false,
      employee_count_options: ['1-10', '11-50', '51-100', '101-250', '501-1000', '1001-5000', '5001-10000', '10000+', 'unknown'],
      saved_queries: null,
      load_query_show: false,
      save_query_show: false,
      load_query: null,
      save_query: null,
      add_filter: null,
      additional_queries: [],
      adv: false
    }
  },

  url: {
    topic_ids: 'topic_ids'
  },
  watch: {
    topic_ids: () => {
        this.getData();
    }
  },

  mounted: function() {
  
      this.host = Config.config.base_host;
      if(!this.selected) {
        this.selected = "No similarity search";
      }
      this.$store.dispatch("get_filter_options");
      
      this.getQueries();
      
      if(this.$route.query.topic_id) {
      
        this.sortBy = 'founding_date';
        this.sources = 'hy';
        this.getData();
      } else if(this.$route.query.keyword) {
        this.keywords = [this.$route.query.keyword];
        this.getData();
      } else {

        if(this.$route.query && this.$route.query.saved_query_id) {
        
          Object.keys(this.filter.custom_data).forEach((key) => {
            this.filter.custom_data[key] = [];
          });

          this.do_load_query(this.$route.query.saved_query_id);
        } else {

         
          Object.keys(this.filter.custom_data).forEach((key) => {
            this.filter.custom_data[key] = [];
            this.filter.custom_data[key].push({"op": null, "q": []});
          });
          this.getData();
        }


      }
   
      

      
  },

  computed: {

    ...mapFields([

        'instance_config',
        'query.selected',
        
        'query.domains',
        'query.similar_list',
        'instance_config.app_config.organizations.search',
        'query.filter',
        'query.filter.keywords',
        'query.filter.full_query',
        'query.filter.sim_domains',
        'query.filter.sim_search',
        'query.filter.q',
        'query.filter.city',
        'query.filter.info',
        'query.filter.custom_data',
        'query.filter.country_code',
        'query.filter.crunchbase_category_list',
        'query.filter.crunchbase_category_groups_list',
        'query.filter.region',
        'query.filter.topic_ids',
        'query.filter.tags',
        'query.filter.watchlist_id',
        'query.filter.categories',
        'query.filter.project_id',
        'query.filter.sources',
        'query.filter.name_str',
        'query.filter.name_list',
        'query.filter.total_funding_usd',
        'query.filter.employee_count',
        'query.filter.founded_on_from',
        'query.filter.founded_on_to',
        'query.filter.current_revenue_usd_from',
        'query.filter.current_revenue_usd_to',
        'query.filter.number_of_funding_rounds',
        'query.filter.last_funding_type',  
        'query.filter.sbti',
        'query.filter.cdp_score',  
        'query.filter.sortBy',
      
        'filter_options.crunchbase_category_list_options',
        'filter_options.tags_options',
        'filter_options.crunchbase_category_groups_list_options',
        'filter_options.categories_options',
        'filter_options.last_funding_type_options',

        'filter_options.country_code_options',
        'filter_options.city_options',
        'filter_options.region_options',
        'filter_options.sbti_options',
        'filter_options.cdp_score_options',

        
        'query.filter.bookmark_hy',
        'query.filter.bookmark_ecosystem'
    ]),
  },
  
  methods: {

    getUUID() {
      return  Math.random();
    },

    updateSticky() {
      this.$emit('updatesticky');
    },

    deleteField(item_list, item) {
      item_list.splice(item_list.indexOf(item), 1);
      this.getData();
    },

    do_save_query(query_name, filter) {
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/queries_v1`, {"name": query_name, "filter": filter}).then(res => {
        this.$router.replace({query: { "saved_query_id": res.body._id }});
        this.getQueries();
        this.load_query = res.body._id;
        this.load_query_show = true;
        this.save_query_show = false;
        this.$store.dispatch("stop_loading");
      });
    },

    do_change_query(query_name, filter, id) {
      this.$store.dispatch("start_loading");
        
      Vue.http.put(`${Config.config.base_host}/queries_v1/${id}`, {"_id": id, "filter": filter}).then(res => {
        this.load_query = res.body._id;
        this.load_query_show = true;
        this.save_query_show = false;
        this.$store.dispatch("stop_loading");
      });
    },

    do_select_query(query_id) {
       Object.keys(this.filter.custom_data).forEach((key) => {
          this.filter.custom_data[key] = [];
        });
        this.do_load_query(query_id);
    },

    do_load_query(query_id) {
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/queries_v1/${query_id}`).then(res => {
        this.load_query = res.body._id;
        this.load_query_show = true;
       
        if(!this.$route.query.saved_query_id) {
          this.$router.replace({query: { "saved_query_id": res.body._id }});
        }

        delete res.body.filter.bookmark_hy;
        delete res.body.filter.bookmark_ecosystem;
        if(res.body.filter.q) {
          this.use_query = true;
        }

        this.filter = res.body.filter;
      
        this.$forceUpdate();
        
       
      });
    },

    getQueries() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/queries_v1`).then(res => {
        this.saved_queries = res.body;
        this.$store.dispatch("stop_loading");
      });
    },

    getData() {

        this.$store.state.search.rows = null;
        this.bookmark_hy = null;
        this.bookmark_ecosystem = null;
        //this.domains = this.$store.getters.project.data.map(x=>x.domain).join("\n");
     
        /*this.$router.push({
          path: this.$route.path,
          query: this.$store.state.query.filter
        });*/
      
        this.$store.dispatch("search");
        
        // if(this.selected.indexOf("No sim") > -1) {
          
        //   this.$store.dispatch("search");
        // } else if(this.selected.indexOf("Project:") > -1) {
        //   console.log(this.$store.state.query.domains);
        //   this.domains = this.$store.getters.project.data.map(x=>x.domain).join("\n");
        //   this.$forceUpdate();
        //   this.$store.dispatch("search_similar"); 
        // } else {
          
        //   this.$store.dispatch("search_similar");
        // }
                
        //this.display();
    },

  },
  components: {
    FilterGroup,
    ProjectStageSelect,
    TopicKeywordSelect,
    CompanyTextSelect,
    TaxonomySelect,
    WatchlistSelect,
    VueSlider,
    TopicSelect
  }
}

</script>
<style lang="scss" scoped>

.form-group {
  margin-bottom: 5px;
}

</style>
