<template lang="pug">
  
.home(id="side-list")
  WListModal(ref="sidewlistmodal")
 
  b-overlay(:show="$store.getters.loading" size="sm", top)
   .mt-2 
    template(v-if="selected_item && selected_item.doc_type=='Organization'")

      b-card()
        template(v-slot:header)
          .d-flex.justify-content-between.align-items-center
                 
          .media
                                    
            router-link(:to="'/ecosystem/orgs/' + selected_item._id")
                div(class="feed-image" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + selected_item.web_entity + ') center 0% / cover no-repeat;'").mr-3
        
            .media-body 
                
              h5.p-0.m-0 
                  router-link(:to="'/ecosystem/orgs/'+selected_item._id") {{selected_item.name}}

              div.links()
                  a(:href="'https://' + selected_item.web_entity" target="_blank"  v-if="selected_item.web_entity").mr-1.small {{selected_item.web_entity}}
                  a(:href="selected_item.homepage_url" target="_blank" v-else-if="selected_item.homepage_url").mr-1.small {{selected_item.homepage_url}}
        
            
            b-btn(size="sm", variant="outline-primary", :to="'/topics/usecases/'+selected_item._id") Open
    
        .mt-0
          span.small(v-if="selected_item.city") {{selected_item.city}}, {{selected_item.country_code}}
          span.small(v-if="!selected_item.city") {{selected_item.address}} 
          
        .mt-3 
          .small {{selected_item.short_description}}
                                    
     
        
      .mt-4

    template(v-if="selectedItems")

      b-card(no-body)
        b-card-header()
          .d-flex.justify-content-between.align-items-center
          
            | {{selectedItems.length}} companies selected 
               
            b-dropdown(size="sm" variant="link" no-caret, right).option-dropdown
                template(v-slot:button-content)
                  i.fas.fa-ellipsis-h.gray-info
                b-dropdown-item(@click="selectedItems = []", href="#", :disabled="selectedItems.length == 0") Clear List
      
                b-dropdown-item(, :disabled="selectedItems.length == 0", size="sm" @click="$refs.sidewlistmodal.open({'name': '', items: selectedItems.map(x => x._id)}, 'organization')") Save List
                  
                b-dropdown-divider()
                b-dropdown-item(size="sm" to="dashboard")  Manage Lists


        .p-3(v-if="selectedItems.length == 0")
          .text-gray.small() Select companies on the left side to add them to a project or to create a new watchlist.
        b-list-group(size="sm", style="max-height: 310px; overflow-y: auto")
          b-list-group-item( v-for="(item, index) in selectedItems").d-flex.justify-content-between.align-items-center.small.m-0.pl-3.pr-0.pt-0.pb-0
            .media
              div(class="feed-image-list-small" :style="'background: url(https://images.hytechnologies.co/unsafe/30x30/logo.clearbit.com/' + item.web_entity + ') center 0% / cover no-repeat;'").mr-3
              .media-body 
                b-link(:to="'/ecosystem/orgs/' + item._id") {{item.name}} 
                
            
            b-btn(variant="link", @click="remove_from_list(item)") <i class="fas fa-times" style="opacity: 0.3"></i>
        .p-2(v-if="selectedItems.length > 0")

          ProjectStageSelect(id="projectStageAdd"  v-model="selectedProjectStage", :create_option="false", placeholder="Select project").small
          .mt-1
            b-btn(variant="outline-primary", @click="addCompaniesToProject(selectedItems, selectedProjectStage)", size="sm", :disabled="!selectedProjectStage").form-control Add to project

</template>

<script>
// @ is an alias to /src

import Config from '@/config'

import Vue from 'vue'
import { mapFields } from 'vuex-map-fields';
import ProjectStageSelect from '@/components/ProjectStageSelect'
import UseCaseSelect from '@/components/UseCaseSelect'
import WListModal from '@/components/WListModal'

export default {
  
  name: 'home',
  
  data: function () {
    return {
    
    }
  },

  mounted: function() {
    console.log(Config);
    this.host = Config.config.base_host;

  },
  watch: {
    'selectedItems': function() {
      

      if(!this.selectedItems || this.selectedItems.length == 0) {
        this.sidebar_visible = false;
      } else {
        this.sidebar_visible = true;
      }
      
      window.dispatchEvent(new Event('resize'));


     
    },

     'selected_item': function(newVar, oldVar) {
      
      let old_sidebar = this.sidebar_visible;

      if(!oldVar && newVar) {
        this.sidebar_visible = true;
      }
      
      if(oldVar && newVar && oldVar._id != newVar._id) {
        this.sidebar_visible = true;
      }

      if(newVar == null && (!this.selected_list || this.selected_list.length == 0)) {
        this.sidebar_visible = false;
      }
      
      if(old_sidebar != this.sidebar_visible) window.dispatchEvent(new Event('resize'));
   
    },
    'selected_list': function() {
      

      if(this.selected_item == null && (!this.selected_list || this.selected_list.length == 0)) {
        this.sidebar_visible = false;
      }
      
      window.dispatchEvent(new Event('resize'));
     
    }

  },

  computed: {
      ...mapFields([
        'selectedItems',
        'selected_item',
        'sidebar.selectedProjectStage',
        'sidebar.selectedUseCase',
        'sidebar.selected_watchlist_id',
        'sidebar_visible',
        'instance_config'
      ]),

  },

  methods: {
    
     add_to_usecase(selectedProjectStage, selectedUseCase, topic_list) {
        this.$store.dispatch("start_loading");
        console.log(selectedProjectStage, topic_list);
        let ids = topic_list.map((x) => { return x._id });
        Vue.http.post(`${Config.config.base_host}/topics_v1/${selectedUseCase}/projects/${selectedProjectStage}/org/bulk`, {use_case_id: selectedUseCase, organization_ids: ids}).then(res => {
          console.log(res);
          location.href = "/projects/"+ selectedProjectStage + '/dashboard';
          this.selectedItems = [];
          this.$store.dispatch("stop_loading");
        });

      },


    addCompaniesToProject(selectedItem, selectedProjectStage) {
        let items = selectedItem.map(x => {
          return { organization_id: x._id, project_id: selectedProjectStage };
        });

        this.$store.dispatch("start_loading");
        Vue.http
          .post(Config.config.base_host + "/project_organizations/bulk", items)
          .then(resp => {
            
            let data = resp.body;
            console.log(data);
            setTimeout(()=>{
                 location.href = "/projects/"+ selectedProjectStage;
            }, 100)
        
          });
      },

      remove_from_list(item) {
        var index = this.selectedItems.map(x => {
          return x._id;
        }).indexOf(item._id);
        
        this.selectedItems.splice(index, 1); 
      }
  },
  components: {
      ProjectStageSelect,
      UseCaseSelect,
      WListModal
  }
}

</script>
<style lang="scss" scoped>


.feed-image {
    height: 50px;
    width: 50px;
}

</style>