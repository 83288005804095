<template lang="pug">

  .home
    .row
      .col-12
        .float-right
          b-input-group()
            
            ProjectStageSelect(v-model="selectedProjectStage", style="min-width: 400px").mr-3
            b-btn(size="sm", @click="addCompaniesToProject($store.getters.selectedItems, selectedProjectStage)")#add-company-btn Add
  
    .row.mt-3
        .col-12(v-if="!$store.state.currentProjectOrganizations")
          | Company is not added to any project yet
        .col-4(v-for="po in $store.state.currentProjectOrganizations")
            ProjectCard(:project="po.relations.project_id", :project_organization="po")
        
</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'

import SearchFilter from '@/views/SearchFilter'

import ProjectCard from '@/components/ProjectCard'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analytics',
  
  data: function () {
    return {
      selectedProjectStage: null
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
  },

   computed: {
        ...mapFields([
            'query.keywords'
        ]),
  },

  methods: {

     addCompaniesToProject(selectedProjectStage) {
        let items = [{"organization_id": this.$route.params.id,  "project_id":selectedProjectStage}];
       
        this.$store.dispatch('start_loading');
        Vue.http.post(Config.config.base_host+'/project_organizations/bulk', items).then(resp => {
            this.$store.dispatch('deselectAll');
            alert("Added " + resp.body.length + " companies to project");
            this.$store.dispatch('stop_loading');
        }); 
        
    },
    
    getData() {
      this.$store.dispatch('getProjects');
    },

  },

  components: {
      ProjectCard,
      SearchFilter,
      ProjectStageSelect
  }

}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>