<template lang="pug">
  
  .home
    .row
        .col-6       
            h6.mt-3.mb-3 Data
            table.table
                tr(v-for="(v,k) in $store.state.currentOrg")
                    td(width="100px") {{k}}
                    td
                        template(v-if="k == '_id' || k == '_rev'")
                            .text-muted {{v}}
                        template(v-else-if="$store.state.currentOrg[k] && $store.state.currentOrg[k].length > 50")
                            b-textarea(type="text",  rows="3", size="sm", v-model="$store.state.currentOrg[k]").form-control
                        template(v-else)
                            b-input(type="text",  size="sm", v-model="$store.state.currentOrg[k]").form-control          
        .col-6
            h6.mt-3.mb-3 Crawl status
                .small {{ crawling_status }}
            h6.mt-3.mb-3 Automatic Analytics
                .small(v-for="(v,k) in analytics")
                    label.mt-2 {{ k }}
                    div {{ v }}
            h6.mt-3.mb-3 Enrichment Candidates
                
            table.table
                tr(v-for="(v,k) in $store.state.currentEnrichments")
                    td(width="100px") {{k}}
                    td
                        template(v-if="k == '_id' || k == '_rev'") 
                            .text-muted {{v}}
                        template(v-else-if="$store.state.currentEnrichments[k] && $store.state.currentEnrichments[k].length > 50")
                            b-textarea(type="text",  rows="3", size="sm", v-model="$store.state.currentEnrichments[k]").form-control
                        template(v-else)
                            b-input(type="text",  size="sm", v-model="$store.state.currentEnrichments[k]").form-control          

                                
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'


//import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'dmin',
  
  data: function () {
    return {
        crawling_status: null,
        analytics: null
    }
  },

  mounted: function() {
    console.log(Config);
    this.host = Config.config.base_host; 
   
    this.getCrawl();
    this.getAnalytics();

    this.$store.dispatch("loadEnrichments", this.$route.params.org_id);
   

  },


   computed: {

     
  },

  methods: {

    getCrawl() { 
   
      Vue.http.get(`${Config.config.base_host}/service/crawler/webpages?web_entity=${this.$store.state.currentOrg.web_entity}`, {}).then(res => {
        this.crawling_status = res.body;

      });

    },

    getAnalytics() { 
   
      Vue.http.get(`${Config.config.base_host}/service/analytics/enrichments?web_entity=${this.$store.state.currentOrg.web_entity}`).then(res => {
        this.analytics = res.body;

      });

    }


  },


  components: {

  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>