<template lang="pug">
  
  .financials-container
     
    .investments-overview().mt-2
     
      h6 EU Funded Projects
      .row.pt-4
        .col-11
          template(v-if="!loaded && projects") No projects found
          template(v-else)
            
            .accordion(role='tablist').w-100
              template(v-for="(project, index) in $_.orderBy(projects, ['start_date'], ['desc'])")
               
                b-card.mb-1(no-body='').mb-3
                  b-card-header.p-3(header-tag='header' role='tab')
                    b-link(block='' v-b-toggle='index + "ac"' variant='link')
                      .d-flex.align-items-center.justify-content-between
                      
                        b {{project.name}}
                        div(style="min-width: 300px")
                          .float-right
                            | Received EU funding: 
                            span(v-if="project.coordinators[0].net_eu_contribution") {{'€' + project.coordinators[0].net_eu_contribution.toString().formatPrice()}}
                            span(v-else) N/A
                  b-collapse(:id='index + "ac"', visible='' accordion='my-accordion' role='tabpanel')
                    b-card-body
                      .row
                        .col-6
                          .mb-2
                            b Objective 
                           
                          p.small {{ project.description }}
                          p.small {{ project.start_date  | moment("DD.MM.YYYY") }} - {{ project.end_date  | moment("DD.MM.YYYY")  }}
                          b-link(:href="project.url", target="_blank").small {{project.url}}
                        .col-2
                        .col-4
                          .mb-2
                            b Participants
                            
                          div(v-for="par in project.participants").mb-2.small
                            b-link(:href="'/ecosystem/orgs/' + par.organization_id") {{par.title}}

                    
                          .mb-2.mt-3
                            b Coordinator
                            
                          div(v-for="collaborator in project.coordinators").small
                            b-link(:href="'/ecosystem/orgs/' + collaborator.organization_id") {{collaborator.title}}

                    

            
         

                    
    
    
          
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Financials',
  data: function () {
    return {
      projects: null,
      loaded: false
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.getData();  
  },
  computed: {
    ...mapFields([
        'query.keywords',
        'currentOrg'
    ]),
  },
  methods: {
    getData() { 
      
      this.$store.dispatch('start_loading'); 
      Vue.http.get(`${Config.config.base_host}/organizations/${this.$route.params.org_id}/public_funding`).then(res => {
          this.projects = res.body;
          this.$store.dispatch("stop_loading");
          this.loaded = true;
      });
    
    },
  }
}

</script>
<style lang="scss" scoped>
  @import '@/_vars.scss';
  #gcontainer {
    height: 500px;
    width: 100%;
  }
  .green{
    color: $green;
  }
  .red{
    color: $red;
  }

  .feed-image {
    height: 50px;
    width: 50px;
  }
  .investors-holder {
    max-height: 300px;
    overflow: auto;
  }
  .table td {
    padding: 0.2em;
  }
  .kpi{
    border-right: 1px solid #4b4b4a;
  }

</style>