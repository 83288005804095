<template lang="pug">

  .home   
    b-overlay(:show="$store.state.loading")
      .row.mt-1(v-if="form && configuration && done_loading")
        
        .d-none.d-md-block.col-md-2
          b-list-group()
           
          
            b-list-group-item(:href="'#'+k", v-for="(v,k) in custom_groups") {{k}}
            
        .col-0.col-md-1
        .col-12.col-md-6
          div(id="listgroup-ex" data-target="#list-example")
            //label(id="project") Organization sheet for {{form.name}}
            
            div(:id="k" v-for="(fields, k) in custom_groups").mb-5
              label().mr-5 {{k}} 
              .float-right.small

                b-link(@click="edit_toggle(k)" v-if="!edit_states[k]", size="sm").gray-info
                  <i class="fas fa-pen"></i>
                b-link(@click="edit_toggle(k)" v-if="edit_states[k]", size="sm").text-muted
                  <i class="fas fa-pen"></i>

              b-card(@click="edit_toggle(k, true)")
                
                .mb-3(v-for="field in fields")
                  label {{field.label}} <i v-b-tooltip="field.description" class="far fa-question-circle" v-if="field.description"></i>
                  
                  .mt-0
                    
                    template(v-if="field.data_type=='slider'") 
                      b-input-group(style="max-width: 400px", size="sm")
                        b-form-input(type="range", v-model="form.custom_data[field.name]", size="sm", :min="1", :step="1", :max="10",  :disabled="!edit_states[k]")
                        b-input(v-model="form.custom_data[field.name]", :disabled="true", style="max-width: 40px")
                    template(v-if="field.data_type=='textarea'", :disabled="!edit_states[k]")
                      b-textarea(v-model="form.custom_data[field.name]", :placeholder="field.name['description']", :disabled="!edit_states[k]")
                    template(v-if="field.data_type=='text'", :placeholder="field.name['description']")
                      b-input(v-model="form.custom_data[field.name]", :disabled="!edit_states[k]")
                    
                    template(v-if="field.data_type=='money_amount'", :placeholder="field.name['description']")
                      template(v-if="field.options.start && form.custom_data[field.name].constructor == Object")
                        b-form-group( label-cols-sm="2", content-cols-sm="8", label="Currency").ml-3.mb-1 
                        
                          b-select(v-model="form.custom_data[field.name]['currency']", :disabled="!edit_states[k]", :options="['EUR', 'USD', 'GBP']", style="max-width: 100px", size="sm")
                        
                        template(v-for="year in $_.range(field.options.start, current_year+6)")
                          b-form-group(:label="year.toString()",  label-cols-sm="2", content-cols-sm="8").ml-3.mb-1 
                            b-row
                              b-col(lg="8")

                                currency-input(v-model="form.custom_data[field.name][year.toString()]", :disabled="!edit_states[k]", :currency="form.custom_data[field.name]['currency'] || 'EUR'")
                            
                              b-col(lg="4")
                                b-check(v-model="form.custom_data[field.name][year.toString()+'_is_estimation']", :disabled="!edit_states[k]") Estimated
                      template(v-else="field.options.start")
                        currency-input(v-model="form.custom_data[field.name]", :disabled="!edit_states[k]")
                

                    template(v-if="field.data_type=='number'", :placeholder="field.name['description']")
                      b-form-input(v-model="form.custom_data[field.name]", :disabled="!edit_states[k]", type="number", :min="0")
                    template(v-if="field.data_type=='date'", :placeholder="field.name['description']")
                      b-form-datepicker(v-model="form.custom_data[field.name]", :disabled="!edit_states[k]")
                    template(v-if="field.data_type=='select'")
                      v-select(v-model="form.custom_data[field.name]", :options="field.options", :placeholder="field.name['description']", :disabled="!edit_states[k]")
                
                .mt-3.float-right(v-if="edit_states[k]")
                  b-btn(variant="link", size="sm", @click="edit_toggle(k)").mr-1 Cancel
                  b-btn(variant="primary", size="sm", @click="save()") Save
              
  
            
          

</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'

import SearchFilter from '@/views/SearchFilter'

import ProjectCard from '@/components/ProjectCard'
import Evaluations from '@/views/orgs/Evaluations'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analytics',
  
  data: function () {
    return {
     form: null,
     evaluations: [],
     configuration: null,
     edit_states: {},
     done_loading: false,
     custom_groups: {},
     current_year: new Date().getFullYear(),
     config: {
        
        element: '.main-view',
        offset: 400,
        method: 'auto',
        throttle: 100
      }
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;

    this.getData();
  },

   computed: {
       
        ...mapFields([
            
        ]),
  },

   watch: {

  },


  methods: {

    edit_toggle(k, cover = false) {

      if(cover && this.edit_states[k]) return;

      let new_state = !this.edit_states[k]
      
      for(let i in this.edit_states) {
        this.edit_states[i] = false;
      }
      if(!this.edit_states[k]) {
        this.edit_states[k] = new_state;
        if(!new_state) {
          this.getData();
        }
      }
      this.$forceUpdate();
    },
    
    getData() {
       this.$store.dispatch('start_loading');
       

        Vue.http.get(`${Config.config.base_host}/configuration/config:main`).then(res => {
          this.custom_groups = {};
          this.configuration = res.body;
           
          this.configuration.data_fields.forEach((x)=> {
              if(!this.custom_groups[x.group]) {
                this.custom_groups[x.group] = []
                this.edit_states[x.group] = false;
              }
              this.custom_groups[x.group].push(x);
          
          });

          Vue.http.get(`${Config.config.base_host}/organizations/${this.$route.params.org_id}`).then(res => {
            this.form = res.body;
            
            this.$_.forEach(this.configuration.data_fields, (v, k) => {
              console.log(k, v.data_type);
              if(v.data_type == 'money_amount' ) {
                
                
               
                if(!this.form.custom_data[v.name] || typeof this.form.custom_data[v.name] != 'object') {
                  Vue.set(this.form.custom_data, v.name, {});
                  if(!this.form.custom_data[v.name]['currency']) Vue.set(this.form.custom_data[v.name], 'currency', "EUR");
                  this.$forceUpdate();
                }
              }
              
              
            });

            this.done_loading = true;


            this.$store.dispatch('stop_loading');
          });

          this.$store.dispatch('stop_loading');
        });

      
    },

     save() {
      this.$store.dispatch('start_loading');
      Vue.http.post(`${Config.config.base_host}/organizations`, this.form).then(res => {
          console.log(res);
          
          if(window.parent) window.parent.postMessage({'event': 'iframe_saved', 'data': res}, '*');

          this.$store.dispatch('stop_loading');
          this.getData();
      });
    },

  },

  components: {
      ProjectCard,
      SearchFilter,
      ProjectStageSelect,
      Evaluations
  }

}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>