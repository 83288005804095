<template lang="pug">

  .home   
    b-overlay(:show="$store.state.loading")
      .row.mt-1
        
        .col-md-4(v-if="$store.state.currentOrg")
         
          template(v-if="po")
              label.mt-1 Project sheet
              ProjectCard(:project="po.relations.project_id", :project_organization="po", :funnel_edit="true", @funnel_changed="save()",  @cancel="getData()").mb-5
          
          //b-list-group().mt-5
            
          
            b-list-group-item(:href="'#'+k", v-for="(v,k) in custom_groups") {{k}}
            
        .col-0.col-md-1
        .col-12.col-md-7
         
          div(id="listgroup-ex" data-target="#list-example")
            

            .text-muted.small(v-if="po && !Object.keys(custom_groups).length").mt-2 There are no custom data fields defined for this project.
            div(:id="k" v-for="(fields, k) in custom_groups" v-if="po").mt-0

            

              label(id="project") {{k}}
              .float-right.small

                b-link(@click="edit_toggle(k)" v-if="!edit_states[k]", size="sm").gray-info
                  <i class="fas fa-pen"></i>
                b-link(@click="edit_toggle(k)" v-if="edit_states[k]", size="sm").text-muted
                  <i class="fas fa-pen"></i>

              b-card(@click="edit_toggle(k, true)").mb-5
                
                .mb-3(v-for="field in fields")
                  label {{field.label}} <i v-b-tooltip="field.description" class="far fa-question-circle" v-if="field.description"></i>
                  .mt-0
                  
                    template(v-if="field.data_type=='textarea'")
                      b-textarea(v-model="po.custom_data[field.name]", :disabled="!edit_states[k]", :placeholder="field['description']", :rows="field.options.rows || 3")
                    template(v-if="field.data_type=='text'", :disabled="!edit_states[k]", :placeholder="field['description']")
                      b-input(v-model="po.custom_data[field.name]", :disabled="!edit_states[k]")
                    template(v-if="field.data_type=='select'")
                      v-select(v-model="po.custom_data[field.name]", :disabled="!edit_states[k]", :options="field.options", :placeholder="field['description']")
                    template(v-if="field.data_type=='date'")
                      b-form-datepicker(v-model="po.custom_data[field.name]", :disabled="!edit_states[k]", :placeholder="field['description']")
                    template(v-if="field.data_type=='money_amount'")
                   
                      currency-input(v-model="po.custom_data[field.name]", :disabled="!edit_states[k]", :currency="field.options.currency", :placeholder="field['description']", )
                    template(v-if="field.data_type=='slider'") 
                      b-input-group(style="max-width: 400px", size="sm")
                        b-form-input(type="range", v-model="po.custom_data[field.name]", :disabled="!edit_states[k]", size="sm", :min="1", :step="1", :max="10")
                        b-input(v-model="po.custom_data[field.name]", :disabled="true", style="max-width: 40px")

                .mt-3.float-right(v-if="edit_states[k]")
                  b-btn(variant="link", size="sm", @click="edit_toggle(k)").mr-1 Cancel
                  b-btn(variant="primary", size="sm", @click="save()") Save

            
            //b-card(v-if="po && Object.keys(po.custom_data).length").mt-5
              label Custom data
              table.table 
                tr(v-for="(v,k) in po.custom_data")
                    td(width="100px") {{k}}
                    td
                        template(v-if="k == '_id' || k == '_rev'") 
                            .text-muted {{v}}
                        template(v-else-if="po.custom_data[k] && po.custom_data[k].length > 50")
                            b-textarea(type="text",  rows="3", size="sm", v-model="po.custom_data[k]").form-control
                        template(v-else)
                            b-input(type="text",  size="sm", v-model="po.custom_data[k]").form-control          

              .mt-3.float-right
                b-btn(variant="primary", size="sm", @click="save()") Save
             
            
            //template(v-for="ev in this.evaluations")
              label.mt-5(id="business") {{ev.description}}
              Evaluations(:currentEvaluation="ev")
            
        
</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'

import SearchFilter from '@/views/SearchFilter'

import ProjectCard from '@/components/ProjectCard'
import Evaluations from '@/views/orgs/Evaluations'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Analytics',
  
  data: function () {
    return {
     po: null,
     evaluations: [],
     project: null,
     custom_groups: null,
     edit_states: {},
     config: {
        
        element: '.main-view',
        offset: 400,
        method: 'auto',
        throttle: 100
      }
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.getData();
  },

   computed: {
       
        ...mapFields([
            'query.keywords'
        ]),
  },

   watch: {
    
    '$route.params.id': function () {
      this.getData();
      
    }
  },


  methods: {
      edit_toggle(k, cover = false) {

        if(cover && this.edit_states[k]) return;

        let new_state = !this.edit_states[k]
        
        for(let i in this.edit_states) {
          this.edit_states[i] = false;
        }

        if(!this.edit_states[k]) {
          this.edit_states[k] = new_state;
          if(!new_state) {
            this.getData();
          }
        }
        this.$forceUpdate();
    },

     addCompaniesToProject(selectedProjectStage) {
        let items = [{"organization_id": this.$route.params.id,  "project_id":selectedProjectStage}];
       
        this.$store.dispatch('start_loading');
        Vue.http.post(Config.config.base_host+'/project_organizations/bulk', items).then(resp => {
            this.$store.dispatch('deselectAll');
            alert("Added " + resp.body.length + " companies to project");
            this.$store.dispatch('stop_loading');
        }); 
        
    },
    
    getData() {
      this.$store.dispatch('start_loading');
      Vue.http.get(`${Config.config.base_host}/project_organizations/${this.$route.params.id}::${this.$route.params.org_id}`).then(res => {
            this.po = res.body;  
            this.project = this.po.relations.project_id;
            this.evaluations = this.po.relations.evaluation_ids;

            this.custom_groups = {};
            
            if(!this.project.data_fields_project_organization) {
              this.project.data_fields_project_organization = [];
            }
            this.project.data_fields_project_organization.forEach((x)=> {
              if(!this.custom_groups[x.group]) this.custom_groups[x.group] = []
              this.edit_states[x.group] = false;
              this.custom_groups[x.group].push(x);
            });
            this.$store.dispatch("stop_loading");
      });

      
    },

     save() {
      this.$store.dispatch('start_loading');
      Vue.http.put(`${Config.config.base_host}/project_organizations/${this.$route.params.id}::${this.$route.params.org_id}`, this.po).then((res) => {      
        if(window.parent) window.parent.postMessage({'event': 'iframe_saved', 'data': res}, '*');
        this.getData()
      });
    },

   
  },

  components: {
      ProjectCard,
      SearchFilter,
      ProjectStageSelect,
      Evaluations
  }

}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>