<template lang="pug">
  
.home(id="ecosystem-list-group", v-if="rows")
  

    .row.mt-1
        .col-2
        .col-8
            div(style="max-height: 400px")
                BenchmarkScatter(v-model="rows")
          
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import TaxonomyDisplay from '@/components/TaxonomyDisplay'
import BenchmarkScatter from '@/components/BenchmarkScatter'




import SearchFilter from '@/views/ecosystem/SearchFilter'

import { mapFields } from 'vuex-map-fields';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  
  name: 'home',
  
  data: function () {
    return {
      currentPage: 1,
      s: {},
      attributes_selected: [{'name': 'revenue', 'label': 'Revenue'},
        {'name': 'revenue_growth_rate', 'label': 'Revenue growth rate'},
        {'name': 'debt_equity_ratio', 'label': 'Debt/equity ratio'},
        {'name': 'profit_margin', 'label': 'Profit Margin'},
        {'name': 'sdg_relevance', 'label': 'SDG relevance'},
        {'name': 'sdg_attribution', 'label': 'SDG attribution'},
        {'name': 'ai_4_good', 'label': 'AI 4 Good'},
        {'name': 'product_maturity', 'label': 'Product maturity'},
        {'name': 'paying_customers', 'label': 'Paying customers'},
        {'name': 'friendly_customers', 'label': 'Friendly customers'},
        {'name': 'funding_rounds', 'label': 'Funding rounds'},
        {'name': 'startup_maturity', 'label': 'Startup maturity'}],
      q: "",
      query: "",
      uuid: null,
      event_results: [],
      sort_options: [{"text": "Last funding date", "value": "last_funding_on"}, {"text": "Total funding", "value": "total_funding_usd"},  {"text": "Entry Date", "value": "source_first_created_at"}, {"text": "Company Rank", "value": "hy_rank"}],

      selected: [],
      selectedNode: {},
      colors: {"retrieval": "rgba(200, 200, 200, 0.8)", "extraction": "rgba(200, 200, 200, 0.8)", "knowledge": "rgba(200, 200, 200, 0.8)", undefined: "rgba(200, 200, 200, 0.8)"},
      sizes: {"Org": 70, "Text": 10, "Topic": 20, "Website": 25, "Webpage": 15, "ImprintInfos": 10},
      current_actions: [{"label": "Remove", "name": "remove"}],
      graphName: "",

      sticky_options: {
        topSpacing: 120,
        resizeSensor: false,
        bottomSpacing: 100
       
      }
    }
  },
filters: {
      round: function(x) {
        if (!x) return '';
        return Math.round(x*100)/100;
      }
},
  mounted: function() {
  
    this.host = Config.config.base_host;
    this.getData();
   
  },

  created() {
   
  },

  beforeDestroy(){
   
  },


  computed: {
      ...mapFields([
        'query.filter.sortBy',
        'query.filter.bookmark_hy',
        'query.filter.bookmark_ecosystem',
        'search.rows'
      ]),

  },

  methods: {


    toggle_selected(item) {
      
      console.log(this.$store.getters.selectedItems.map((x)=>{return x._id}));
      if(this.$store.getters.selectedItems.map((x)=>{return x._id}).indexOf(item._id)>-1) {
        this.$store.dispatch("remove_selected", [item]);
      } else {
        this.$store.dispatch("add_selected", [item]);
      }
    },
    
    heatMapColorforValue(percentage, hue0 = 120, hue1 = 0) {
      var hue = ((1 - percentage) * (hue1 - hue0)) + hue0;
      return 'hsl(' + hue + ', 100%, 50%, 0.2)';
    },

    calc_data(value, field) {

      function scale(val, max, min) { return (val - min) / (max - min); }
      
      let all_val = this.rows.map(row => {
        if(row.index_space && row.index_space.startup_standard_survey &&  row.index_space.startup_standard_survey.custom_data && row.index_space.startup_standard_survey.custom_data[field] != undefined) {
          return row.index_space.startup_standard_survey.custom_data[field];
        }
      }).filter(x => x != null);

      let min = Math.min(...all_val);
      let max = Math.max(...all_val);
      
      return scale(value, max, min);
    
    },


    getData(state) {
        this.$store.dispatch("search", state);
    },

     getDataClear() {
        this.bookmark_hy = null;
        this.bookmark_ecosystem = null;
        this.$store.dispatch("search");
       
        //this.display();
    },

  },

  components: {
      InfiniteLoading,
      SearchFilter,
      TaxonomyDisplay,
      BenchmarkScatter
  }
}

</script>
<style lang="scss" scoped>

 


</style>