<template lang="pug">
.h-submenu-link.d-inline-block
    .d-inline-block(@mouseover="onOver('dd1')", @mouseleave="onLeave('dd1')", v-bind:class="{'link-active': active}")
        b-nav-item-dropdown(ref="dd1" :text="title", no-caret)
          template(#button-content) 
            div(@click="$router.push(links[0].to)").p-0 {{title}}
          b-dropdown-item(:to="link.to", v-for="link in links") {{link.name}}
    
</template>

<script>
// @ is an alias to /src

export default {
  
  name: 'HSubmenuLink',
  props: ["links", "title", "no-caret"],
  data: function () {
    return {
      uid: null,
      showMore: false,
      active: false
    }
  },

 

  watch: {
    "$route.path": function() {
      setTimeout(()=>{
       this.active = false;
       if(this.$refs.dd1.$children) {
        if(this.$refs.dd1.$children.some(x => x.$el.getElementsByClassName('router-link-active').length > 0)) {
          this.active = true;
        } 
      }}, 200);

    }
  },

  mounted: function() {
     
    
    if(this.$refs.dd1.$children) {
       if(this.$refs.dd1.$children.some(x => x.$el.getElementsByClassName('router-link-active').length > 0)) {
         this.active = true;
       }
    }
   
   
  },

     
  
  methods: {

    check() {
     
       if(this.$refs.dd1 && this.$refs.dd1.$children) {
          if(this.$refs.dd1.$children.some(x => x.$el.getElementsByClassName('router-link-active').length > 0)) {
            return true;
          } 
        }
        return false;
    }, 

    update() {
        this.$emit("update");
    },
    onOver(el) {
        this.$refs[el].show();
    },
    onLeave(el) {
        this.$refs[el].hide();
    }
  },

  components: {

  }
}

</script>

<style lang="scss" >
@import '@/_vars.scss';
.link-active > li > a {
   
      color: $navbar-active-color !important;

}

</style>
<style lang="scss" scoped>

@import '@/_vars.scss';

li { 
    list-style: none;
}

.link-active {
    transition: border 0.4s ease-in-out;
    border-bottom: 5px solid $navbar-active-color;
    li a {
      color: $navbar-active-color !important;
    }
}

</style>
