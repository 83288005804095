<template lang="pug">
.overview
  .row
    .col-12 
      
    div.mb-2.full-width.mt-3(style="width: 100%")
      .row
        .col-12.col-lg-3
          b-form-group(label="Smart Preset")
            b-select(
              size="sm",
              @change="change_preset(preset_selected)",
              placeholder="Load preset",
              v-model="preset_selected",
              :options="preset_options"
            )

        .col-12.col-lg-9
          .float-right
            b-form(inline, style="width: 100%")
              b-form-group.mr-3(label="X-Axis")
                b-select(
                  size="sm",
                  @change="initScatter(internal_value)",
                  :options="x_options",
                  v-model="x_selected"
                )

              b-form-group(label="Y-Axis")
                b-select(
                  size="sm",
                  @change="initScatter(internal_value)",
                  :options="y_options",
                  v-model="y_selected"
                )

      .form-row
        .col-sm-12(style="background-color: transparent")
          //.yaxislabel(style="white-space: nowrap").small 
            b {{customer_value_options.filter( x => x.value == customer_value_selected)[0]['text']}} (%)
          #viz2
          .toolbar
            b-button-group(vertical)
            b-btn(@click="zoomIn()", variant="link", size="sm")
              i.fas.fa-search-plus
            b-btn(@click="zoomOut()", variant="link", size="sm")
              i.fas.fa-search-minus
      //.row
        .col-12
          center
            b(v-if="x_selected")
            b-row.small(style="max-width: 600px")
              b-col 
              i.fas.fa-arrow-left.mr-2
              | Slower
              b-col.text-center <b>Time to market (month)</b>
              b-col 
              | Faster
              i.fas.fa-arrow-right.ml-2

      .row.mt-5
        .col-6
        
            .text-gray.small {{ number_evaluations }} Companies have been evaluated
        .col-6
          .float-right
            b-form(inline)
            b-form-group.mr-5(label="Color: Company maturity")
              div
              .box.small(:style="{ color: heatMapColorforValue(0.1) }") 1
              .box.small(:style="{ color: heatMapColorforValue(0.2) }") 2
              .box.small(:style="{ color: heatMapColorforValue(0.5) }") 5
              .box.small(:style="{ color: heatMapColorforValue(0.7) }") 7
              .box.small(:style="{ color: heatMapColorforValue(1) }") 10
            b-form-group(label="Size: Number of use cases")
              .dot.small(style="padding: 6px; width: 20px; height: 20px;") 1
              .dot.small(style="padding: 8px; width: 25px; height: 25px;") 2
              .dot.small(style="padding: 11px; width: 30px; height: 30px;") 5
              .dot.small(style="padding: 12px 4px; width: 35px; height: 35px;") &gt; 10
</template>

<script>
import Config from "@/config";

import { mapFields } from "vuex-map-fields";

import * as d3 from "d3";

export default {
  name: "TopicOverview",
  props: {
    value: {
      default: [],
    },
  },
  data: function () {
    return {
      internal_value: null,
      xScale: null,
   
      survey_type_selected: "use_case",
      number_evaluations: 0,
      svg: null,
      data: null,
      loaded: false,
      x_selected: "revenue",
      y_selected: "competition",
      preset_selected: "bestsellers",

      preset_options: [
        { value: "bestsellers", text: "Bestsellers" },
        { value: "deloittes_choice", text: "Deloitte's Choice (Launching soon)", disabled: true},
        { value: "fastest_growing", text: "Fastest Growing" },
        { value: "newcomers", text: "Newcomers" },
        { value: "sustainability_drivers", text: "Sustainability Drivers" },
        { value: "ai_4_good", text: "AI 4 Good" },
      ],

      preset_mappings: {
        bestsellers: { x: "revenue", y: "competition" },
        fastest_growing: { x: "revenue_growth_rate", y: "clients" },
        newcomers: { x: "revenue_growth_rate", y: "product_maturity" },
        sustainability_drivers: { x: "sdg_relevance", y: "brand_awareness" },
        ai_4_good: { x: "sdg_relevance", y: "product_maturity" },
      },

      x_options: [
        { value: "revenue", text: "Revenue" },
        { value: "revenue_growth_rate", text: "Revenue growth rate" },
        { value: "debt_equity_ratio", text: "Debt/equity ratio" },

        { value: "competition", text: "Competition" },
        {
          value: "marketing_and_sales_budget",
          text: "Marketing and sales budget",
        },
        { value: "clients", text: "Clients" },
        { value: "brand_awareness", text: "Brand awareness" },

        { value: "profit_margin", text: "Profit Margin" },
        { value: "sdg_relevance", text: "SDG relevance" },
        { value: "sdg_attribution", text: "SDG attribution" },
        { value: "ai_4_good", text: "AI 4 Good" },
        { value: "product_maturity", text: "Product maturity" },
        { value: "paying_customers", text: "Paying customers" },
        { value: "friendly_customers", text: "Friendly customers" },
        { value: "funding_rounds", text: "Funding rounds" },
        { value: "startup_maturity", text: "Startup maturity" },
      ],

      y_options: [
        { value: "revenue", text: "Revenue" },
        { value: "revenue_growth_rate", text: "Revenue growth rate" },
        { value: "debt_equity_ratio", text: "Debt/equity ratio" },

        { value: "competition", text: "Competition" },
        {
          value: "marketing_and_sales_budget",
          text: "Marketing and sales budget",
        },
        { value: "clients", text: "Clients" },
        { value: "brand_awareness", text: "Brand awareness" },

        { value: "profit_margin", text: "Profit Margin" },
        { value: "sdg_relevance", text: "SDG relevance" },
        { value: "sdg_attribution", text: "SDG attribution" },
        { value: "ai_4_good", text: "AI 4 Good" },
        { value: "product_maturity", text: "Product maturity" },
        { value: "paying_customers", text: "Paying customers" },
        { value: "friendly_customers", text: "Friendly customers" },
        { value: "funding_rounds", text: "Funding rounds" },
        { value: "startup_maturity", text: "Startup maturity" },
      ],

      zoom: null,
      topics: null,
      total_rows: null,
    };
  },

  beforeDestroy() {},

  beforeMount() {},

  mounted: function () {
    this.internal_value = this.value;
    this.host = Config.config.base_host;

    this.toggle_selected(null, false);
    d3.select("#viz2").append("svg").attr("width", 840).attr("height", 400).call(this.responsivefy);

    if (this.internal_value) {
      this.initScatter(this.internal_value);
      this.$eventHub.$emit("resize");
    }
  },

  computed: {
    ...mapFields([

      "selected_item",
      "selectedItems",
   
    ]),
  },

  watch: {
    selected_list: function () {
      this.recolor();
    },

    internal_value: function (value) {
      this.initScatter(value);
      this.$eventHub.$emit("resize");
    },
  },

  methods: {
    responsivefy(svg) {
      // get container + svg aspect ratio
      console.log(svg, svg.node());
     var container = d3.select(svg.node().parentNode),
        width = parseInt(svg.style("width")),
        height = parseInt(svg.style("height")),
        aspect = width / height;

      // add viewBox and preserveAspectRatio properties,
      // and call resize so that svg resizes on inital page load
      //svg.attr("viewBox", "0 0 " + width + " " + height)
      //  .attr("perserveAspectRatio", "xMinYMid")
      svg.call(resize);

      // to register multiple listeners for same event type,
      // you need to add namespace, i.e., 'click.foo'
      // necessary if you call invoke this function for multiple svgs
      // api docs: https://github.com/mbostock/d3/wiki/Selections#on
      d3.select(window).on("resize." + container.attr("id"), resize);

      var vm = this;

      function resize() {
        setTimeout(() => {
          var targetWidth = parseInt(container.style("width"));
          //var targetHeight = parseInt(container.style("width"));

          if (targetWidth) {
            svg.attr("width", targetWidth);
            svg.attr("height", Math.round(targetWidth / aspect));

            vm.initScatter(vm.internal_value);
          }
        }, 0);
      }
    },

    change_preset(selected) {
      this.x_selected = this.preset_mappings[selected].x;
      this.y_selected = this.preset_mappings[selected].y;

      this.initScatter(this.internal_value);
    },

    toggle_selected(item, add = true) {
      this.selected_item = item;

      if (add) {
        if (
          
          this.selectedItems
            .map((x) => {
              return x._id;
            })
            .indexOf(item._id) > -1
        ) {
          var index = this.selectedItems
            .map((x) => {
              return x._id;
            })
            .indexOf(item._id);

          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(item);
        }
      }
    },

  
    heatMapColorforValue(value) {
      var h = value * 240;
      return "hsla(" + h + ", 40%, 60%, 80%)";
    },

   recolor() {

      this.data.forEach((it) => {
        let topic = it.org;

        this.svg.selectAll('#s'+topic._id).style("fill", this.heatMapColorforValue(topic.evaluations.startup_maturity ? topic.evaluations.startup_maturity/10 : 0)).style("stroke-width", 3);
        if(this.selectedItems && this.selectedItems.map((x)=>{return x._id}).indexOf(topic._id)>-1) {
              this.svg.selectAll('#s'+topic._id).style("stroke", 'white');
            } else {    
               this.svg.selectAll('#s'+topic._id).style("stroke", 'transparent');
            }
        if(this.selected_item && this.selected_item._id == topic._id) {
       
          //this.svg.selectAll('#s'+topic._id).style("fill", 'transparent');
          this.svg.selectAll('#s'+topic._id).style("stroke", 'white');
        } else {    
            console.log();

        }


      });
      
    },

    redraw_scatter() {
      console.log("Redraw");

      var margin = { top: 10, left: 20 };

      // Draw Datapoints
      var points_g = this.svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .attr("clip-path", "url(#clip)")
        .classed("points_g", true);

      this.points = points_g
        .selectAll("circle")
        .data(this.data)
        .enter()
        .append("circle")
        .attr("cx", (d) => {
          return this.xScale(d.x);
        })
        .attr("cy", (d) => {
          return this.yScale(d.y);
        })
        .style("fill", function (d) {
          return d.color;
        })
        .attr("cursor", "pointer")
        .attr("id", function (d) {
          return "s" + d.org._id;
        })
        .attr("r", function (d) {
          return d.r;
        })
        .on("click", (d, data) => {
          tip.transition().delay(0).style("display", "none");
          addmenu.style("display", "none");

          if (
            this.selectedItems
            .map((x) => {
              return x._id;
            })
            .indexOf(data.org._id) > -1
            ) {
              addmenu.html("Remove")
            } else {
              addmenu.html("Add")
            }


            addmenu.style("left", d.x + 20 + "px").style("top", d.y - 20 + "px").style("cursor", "pointer").style("display", "block");

            addmenu.on("click", () => {
              this.toggle_selected(data.org, true);

            if (
            this.selectedItems
            .map((x) => {
              return x._id;
            })
            .indexOf(data.org._id) > -1
            ) {
              addmenu.html("Remove")
            } else {
              addmenu.html("Add")
            }



            });

     
          

          this.toggle_selected(data.org, false);
          
          this.recolor();
        })
        .on("mouseover", function (d, data) {
          tip.transition().duration(0);
          tip.html(data.org.name);
          tip.style("top", d.y - 20 + "px");
          tip.style("left", d.x + 20 + "px");
          tip.style("display", "block");
        })
        .on("mouseout", function () {
          tip.transition().delay(1).style("display", "none");
        });

      var tip = d3
        .select("body")
        .append("div")
        .attr("class", "tip")
        .html("...")

        .style("padding", "3px")
        .style("position", "absolute")
        .style("display", "none")
        .style("color", "white")
        .on("mouseover", function () {
          tip.transition().duration(0);
        })
        .on("mouseout", function () {
          tip.style("display", "none");
        });

       var addmenu = d3
        .select("body")
        .append("div")
        .attr("class", "addmenu small")
        .html("Add")

        .style("padding", "3px")
        .style("position", "absolute")
        .style("display", "none")
        .style("color", "white")
        .on("mouseover", function () {
          addmenu.transition().duration(0);
        })
        .on("mouseout", function () {
          addmenu.style("display", "none");
        });

      this.recolor();
    },

    zoomed(event) {
      // create new scale ojects based on event
      this.new_xScale = event.transform.rescaleX(this.xScale);
      this.new_yScale = event.transform.rescaleY(this.yScale);

      this.gX.call(this.xAxis.scale(this.new_xScale));
      this.gY.call(this.yAxis.scale(this.new_yScale));

      this.points
        .data(this.data)

        .attr("cx", (d) => {
          return this.new_xScale(d.x);
        })
        .attr("cy", (d) => {
          return this.new_yScale(d.y);
        });
    },

    zoomIn() {
      this.svg.transition().call(this.zoom.scaleBy, 1.5);
    },

    zoomOut() {
      this.svg.transition().call(this.zoom.scaleBy, 0.75);
    },

    initScatter(topics) {

      var paras = document.getElementsByClassName("tip");
      while(paras[0]){ paras[0].parentNode.removeChild(paras[0])}
      paras = document.getElementsByClassName("addmenu");
      while(paras[0]){ paras[0].parentNode.removeChild(paras[0])}

      if (!topics) return;
      
      this.svg = d3.select("#viz2 svg");
    
      var width = this.svg.attr("width"),
        height = this.svg.attr("height");

      if(!height) return;

      this.data = this.genRandomData(this.$store, topics);
      let arX = this.data.map((x) => x.org.x);
      let arY = this.data.map((x) => x.org.y);

      console.log("mydata", this.data);

      let maxX = Math.round(Math.max(...arX) * 1.5);
      let maxY = Math.round(Math.max(...arY) * 1.5);

     d3.selectAll("svg > *").remove();
      
      width = 1 * width;
      height = 1 * height;


      var margin = { top: 20, bottom: 20, left: 20 };
      console.log(this.svg, width, height)

      // create a clipping region
      this.svg
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      // create scale objects
      this.xScale = d3
        .scaleLinear()
        .domain([0, maxX + 10])
        .range([-10,width]);

      this.yScale = d3
        .scaleLinear()
        .domain([0, maxY + 10])
        .range([height, 0]);

      // create axis objects
      this.xAxis = d3
        .axisBottom(this.xScale)
        .ticks(20, "s")
        .tickFormat(function (d) {
          if (d === 0) return d;  
          else if (d < 0) return ""; // No nagative labels
          if (d > 1000000) return (d/1000000) + "M";
          if (d > 10000) return (d/1000) + "K";
          return d;
        });
      this.yAxis = d3
        .axisLeft(this.yScale)
        .ticks(20, "s")
        .tickFormat(function (d) {
          if (d === 0) return d;
          // No label for '0'
          else if (d < 0) return ""; // No nagative labels
          if (d > 1000000) return (d/1000000) + "M";
          if (d > 10000) return (d/1000) + "K";
          
          return d;
        });

      this.xAxis2 = d3
        .axisBottom(this.xScale)
        .ticks(20, "s")
        .tickFormat(function (d) {
          if (d === 0) return "";
          // No label for '0'
          else if (d < 0) return ""; // No nagative labels
          return d;
        });

      this.yAxis2 = d3
        .axisLeft(this.yScale)
        .ticks(20, "s")
        .tickFormat(function (d) {
          if (d === 0) return "";
          // No label for '0'
          else if (d < 0) return ""; // No nagative labels
          return d;
        });

      // Draw Axis
      this.gX = this.svg
        .append("g")
        .attr(
          "transform",
          "translate(" + margin.left + "," + (height - margin.top) + ")"
        )
        .call(this.xAxis)
        .call(this.xAxis2)
        .call((g) =>
          g
            .append("text")
            .attr("x", width - 20)
            .attr("y", margin.bottom - 30)
            .attr("font-weight", 600)
            .attr("fill", "currentColor")
            .attr("text-anchor", "end")
            .text(
              this.x_options.filter((x) => x.value == this.x_selected)[0].text +
                " ⇨"
            )
        );

      this.gY = this.svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .call(this.yAxis)
        .call(this.yAxis2)
        .call((g) =>
          g
            .append("text")
            .attr("x", 10)
            .attr("y", 10)
            .attr("font-weight", 600)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text(
              "⇧ " +
                this.y_options.filter((x) => x.value == this.y_selected)[0]
                  .text +
                ""
            )
        );

      console.log("axis finssihed");
      this.redraw_scatter();

      let first = true;
      // Pan and zoom
      if (!this.zoom) {
        this.zoom = d3
          .zoom()
          .scaleExtent([0.8, 5])
          //.extent([[0, 0], [width, height]])
          .clickDistance(1000);
        first = true;
      }

      this.zoom.on("zoom", this.zoomed);

      if (first) {
        this.svg.transition().call(this.zoom.scaleBy, 0.1);
      }

      this.new_xScale = null;
      this.new_yScale = null;

      this.svg.call(this.zoom);
    },

    genRandomData(store, passed) {
      var data = [];
      var datapoint = {};

      passed.forEach((org) => {
        datapoint = {};
        if (!data.map((y) => y.org._id).includes(org._id)) {
          if (
            org.index_space &&
            org.index_space["startup_standard_survey"] &&
            org.index_space["startup_standard_survey"]["custom_data"]
          ) {
            let out = false;

            org.evaluations =
              org.index_space["startup_standard_survey"]["custom_data"];
            try {
              org.x = org.evaluations[this.x_selected];
              org.y = org.evaluations[this.y_selected];
            } catch {
              out = true;
            }
            datapoint["x"] = org.x;
            datapoint["y"] = org.y;

            if (
              !org.index_space.project_topics ||
              org.index_space.project_topics.length <= 1
            ) {
              datapoint["r"] = 10;
            } else if (org.index_space.project_topics.length < 5) {
              datapoint["r"] = 12;
            } else if (org.index_space.project_topics.length < 10) {
              datapoint["r"] = 15;
            } else {
              datapoint["r"] = 17;
            }

            datapoint["org"] = org;
            if (datapoint["x"] !== undefined && datapoint["y"] !== undefined) {
              if (!out) data.push(datapoint);
            }
          }
        }
      });

      this.number_evaluations = data.length;

      return data;
    },
  },

  components: {},
};
</script>
<style lang="scss" scoped>
@import "@/_vars.scss";
a {
  text-decoration: none !important;
}

.box {
  display: inline-block;
  padding: 2px;
}

.dot {
  line-height: 0.875em;
  color: black;
  background-color: #4b4b4a;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.75em;
  margin-right: 5px;
}

.yaxislabel {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.uc-name {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  display: inline-block;
}

.toolbar {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $main-view-background-color;
  a {
    color: $gray-800;
  }
}
</style>