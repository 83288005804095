<template lang="pug">

  .home   
    b-overlay(:show="$store.state.loading")
      .row.mt-1(v-if="api")

        .col-12
            label {{api.name}} 
           
            div(v-html="api.content")
           
            
          

</template>

<script>
// @ is an alias to /src

import Vue from 'vue';
import Config from '@/config'

import SearchFilter from '@/views/SearchFilter'

import ProjectCard from '@/components/ProjectCard'
import Evaluations from '@/views/orgs/Evaluations'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Connect',
  
  data: function () {
    return {
     form: null,
     api: null,
    
 
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;

    this.getData();
  },

   computed: {
       
        ...mapFields([
            
        ]),
  },

   watch: {

  },


  methods: {

     
    
    getData() {
       this.$store.dispatch('start_loading');
      

        Vue.http.post(`${Config.config.base_host}/api_configurations/${this.$route.params.id}/execute`, {"organization_id": this.$route.params.org_id}).then(res => {
          this.api = {};
          this.api = res.body;
          
          this.$store.dispatch('stop_loading');
        });

      
    },

     save() {
      this.$store.dispatch('start_loading');
      Vue.http.post(`${Config.config.base_host}/organizations`, this.form).then(res => {
          console.log(res);
          this.$store.dispatch('stop_loading');
          this.getData();
      });
    },

  },

  components: {
      ProjectCard,
      SearchFilter,
      ProjectStageSelect,
      Evaluations
  }

}

</script>
<style lang="scss" scoped>

</style>