<template lang="pug">

  .assessments(v-if="surveys != null && $store.state.user")
    div(v-if="!surveys.length") You have not sent any surveys yet.
    .row      
        .col-12.col-lg-3
           
            //b-btn(variant="outline-primary", size="sm", @click="send_standard_survey()", v-if="$auth.has_access('survey', null, 'add')").form-control Send startup survey

            b-list-group(style="overflow-y: auto; max-height: calc(100vh - 300px)", v-if="surveys != null").mt-3
                b-list-group-item(:active="selected_survey == null" ).mb-2 
                    div
                      h3 {{surveys.length}}
                      .label.gray-info Surveys<br/>

            b-list-group(style="overflow-y: auto; max-height: calc(100vh - 400px)", v-if="surveys != null").mt-3
                template(v-for="survey in surveys")
                    b-list-group-item(:active="selected_survey == survey" button,  @click="selected_survey=survey").d-flex.justify-content-between.align-items-center 
                        //.media
                            .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                            .media-body {{survey.relations.organization_id.name}}
                        div
                            b {{survey.relations.survey_template_id.name}}
                            div 
                                label.mt-1(v-if="survey.project_id") Project: {{survey.relations.project_id.name}}
                                label.mt-1(v-if="survey.topic_id") Use case: {{survey.relations.topic_id.name}}
                            div 
                                
                                label.mt-1(v-if="survey.responded_at") Answered
                                label.mt-1(v-if="!survey.responded_at") Open
                          
                     
                        .text-gray.small {{survey.updated_at | utcFrom}} 

        .col-lg-1
        .col-12.col-lg-8(style="max-width: 800px")
          
          template(v-if="selected_survey")
              template()
               
                Survey(:survey_template="selected_survey.relations.survey_template_id", @saved="getData()" v-model="selected_survey", :user="selected_survey.relations.owner_id", :edit_mode="!selected_survey.responded_at", :editable="!selected_survey.responded_at || selected_survey.survey_type == 'standard_survey'")
    
              b-card(no-body).mt-5
                  b-card-header()
                    b History
                  .p-3
                    .media
                        .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + selected_survey.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                        .media-body {{selected_survey.relations.organization_id.name}}, {{selected_survey.relations.organization_id.web_entity}}
                    table.table.small.mt-3

                        tr
                            td() Survey Link
                            td() 
                                b-link(:href="get_survey_link(selected_survey)", target="_blank") Link

                        tr
                            td() Created
                            td() {{selected_survey.created_at | moment("DD.MM.YYYY hh:mm")}}

                        tr
                            td() Sent at
                            td() 
                                | {{selected_survey.sent_at | moment("DD.MM.YYYY hh:mm")}}
                                b-btn(@click="send_surveys([selected_survey])", variant="outline-primary", size="sm", v-if="!selected_survey.sent_at") Send survey
                        
                        tr
                            td() Opened at
                            td() {{selected_survey.opened_at | moment("DD.MM.YYYY hh:mm")}}
                        
                        tr
                            td() Responded at
                            td() {{selected_survey.responded_at | moment("DD.MM.YYYY hh:mm")}}

                        tr
                            td() Matched at
                            td() {{selected_survey.confirmed_at | moment("DD.MM.YYYY hh:mm")}}

</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import Survey from '@/components/Survey.vue'
import SurveyAggregation from '@/components/SurveyAggregation.vue'

import User from '@/components/User.vue'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Assessement',
  
  data: function () {
      return {
          to_send: [],
          to_match: [],
          to_resend: [],
          survey_template: null,
          selected_survey: null,
          aggregation: null,
          surveys: null,
          save_selected_id: null,
          has_own_assessment: false,
          sort_by: 'name'
    
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
  
  },

  watch: {
    
  },

  computed: {
       
        ...mapFields([
            'sidebar_project.selected_item'
        ]),
  },

  
  methods: {

      get_group(status, surveys) {
          
          if(status == 'Considered') return surveys.filter(survey => !survey.sent_at);
          if(status == 'Sent') return surveys.filter(survey => survey.sent_at && !survey.responded_at);
          if(status == 'Responded') return surveys.filter(survey => survey.responded_at && !survey.confirmed_at);
          if(status == 'Matched') return surveys.filter(survey => survey.confirmed_at);
      },

      create_new() {
        this.selected_survey = {"relations": {"owner_id": this.$store.state.user}, "custom_data": {}, "owner_id": this.$store.state.user.id, "survey_type": "standard_survey", "organization_id": this.$route.params.org_id};
      },

      get_survey_link(survey) {
          return window.location.protocol + "//" + window.location.host + '/surveys_external/' +  survey.link_token;
      },

      send_surveys(surveys, resend = false) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/send_bulk`, {"survey_ids": surveys.map(x => x._id), "resend": resend}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },

    send_standard_survey() {
        Vue.http.post(`${Config.config.base_host}/surveys`, {"do_sent": true, "survey_type": "standard_survey", survey_template_id: "SurveyTemplate:startup_standard_survey", organization_id: this.$route.params.org_id}).then(() => {
            this.$bvToast.toast("Survey send", {
              title: 'Saved',
              variant: 'outline-primary',
              autoHideDelay: 1000,
            });
            this.getData()
        }).catch(res => {
            this.$bvToast.toast(res.body.detail, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 1000,
            });


        });
    },

    match_surveys(surveys) {
        this.$store.dispatch("start_loading");
        this.save_selected_id = this.selected_survey._id;
        Vue.http.post(`${Config.config.base_host}/surveys/match_bulk`, {"survey_ids": surveys.map(x => x._id)}).then(() => {
            this.getData();
            this.$store.dispatch("stop_loading");
        });
      },
    
      getData() {
        
        Vue.http.post(`${Config.config.base_host}/surveys/search`, {"organization_id": [this.$route.params.org_id], "enrich": ['organization_id', 'survey_template_id']}).then(res => {
            this.surveys = res.body;

            if(this.save_selected_id) {
                try {
                    this.selected_survey = this.surveys.filter(s => this.save_selected_id == s._id)[0];
                    console.log(this.selected_survey);
                } catch {
                    this.selected_survey = null;
                }

                this.save_selected_id= null;
            }

            this.has_own_assessment = this.surveys.filter(x => x.owner_id == this.$store.state.user.id).length > 0;
            this.$store.dispatch("stop_loading");
        });


      
      }
     
  },
  components: {
      Survey,
      SurveyAggregation,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>