<template lang="pug">
  
  .home(v-if="$store.state.currentOrg && $store.state.currentOrg != {} && $store.state.currentOrg._id")
   
    .row
        .col-12.col-lg-6
            
            div(style="max-width: 600px").mb-3
            
                h5.mt-3 Info
               
                p(style="max-width: 500px").small.mt-1#company-profile-short_description {{$store.state.currentOrg.short_description}}
                p(style="max-width: 500px").small.mt-1#company-profile-description.line-clamp-10(v-bind:class="{'clicked': read_more}" @click="read_more=!read_more") {{$store.state.currentOrg.description}} 
              
                //p(style="max-width: 500px").small.text-muted#description {{$store.state.currentOrg.description}} 

                .text-muted(v-if="$store.state.currentOrg.categories && $store.state.currentOrg.categories.length")#categories
                    label.mt-1.d-inline-block Organization Type
                    .mt-1
                        .badge.badge-secondary(v-for="cat in $store.state.currentOrg.categories").mr-1 {{cat}}

                p.text-muted(v-if="$store.state.currentOrg.tags && $store.state.currentOrg.tags.length")#tags
                    label.d-inline-block() Tags 
                        .badge.badge-secondary(v-for="tag in $store.state.currentOrg.tags", @click="getData(tag)", style="cursor:pointer").mr-1 {{tag}}
                
                b-card(v-if="kws_debug").mb-2
                    div(v-for="item in kws_debug")
                        .small Keyword "{{item.kws}}"
                        div
                            a(:href="item.webpage[0]", target="_blank") {{item.webpage[0]}}
                            //div(v-for="text in item.texts").mt-4.small
                                div(v-html="text")
                p.text-muted.small(v-if="$store.state.currentOrg.phone_number") Phone: {{$store.state.currentOrg.phone_number}}
                p.text-muted.small(v-if="$store.state.currentOrg.email") 
                    | Email: 
                    b-link(:href="'mailto:'+$store.state.currentOrg.email") {{$store.state.currentOrg.email}}
                
                template(v-if="$store.state.currentOrg && $store.state.currentOrg.custom_data.youtube_url && get_youtube_id($store.state.currentOrg.custom_data.youtube_url)")
                 
                    div(class="embed-responsive embed-responsive-16by9")
                        <iframe class="embed-responsive-item" :src="'https://www.youtube.com/embed/' + get_youtube_id($store.state.currentOrg.custom_data.youtube_url)" allowfullscreen></iframe>

                                
                table.table.mt-3
                    tr()(v-if="$store.state.currentOrg.created_at")
                        td()
                            label Added to ecosystem
                        td#create_at().text-right
                            .small {{$store.state.currentOrg.created_at | moment("DD.MM.YYYY")}}
                    
                    tr(v-if="$store.state.currentOrg.updated_at")
                        td()
                            label Last update
                        td#updated_at().text-right
                            .small {{$store.state.currentOrg.updated_at | moment("DD.MM.YYYY")}}
                    
            
                template(v-if="$store.state.currentOrg.registration_subject")
                    label.mt-3 Company Registry
                    p.small.text-muted#description {{$store.state.currentOrg.registration_subject}}
                    table.table().mt-3
                        
                        tr(v-if="$store.state.currentOrg.registration_date")
                            td()
                                label Registration date
                            td#reg_date().text-right
                                | {{$store.state.currentOrg.registration_date}}

                        tr()
                            td()
                                label Managing Directors
                            td#md().small.text-right {{$store.state.currentOrg.managing_directors.join(", ")}}
                        
                        tr(v-if="$store.state.currentOrg.registration_date")
                            td()
                                label Registration date
                            td#registration_date().small.text-right
                                | {{$store.state.currentOrg.registration_date}}
                        
                        tr(v-if="$store.state.currentOrg.address")
                            td()
                                label Address
                            td#address().small.text-right
                                | {{$store.state.currentOrg.address}}

                template(v-if="$store.state.currentOrg.employee_count || $store.state.currentOrg.founding_date").mb-3
                    
                    table.table
                        tr()(v-if="$store.state.currentOrg.employee_count")
                            td()
                                label Employee Count
                            td#employee_count().text-right
                                .small {{$store.state.currentOrg.employee_count}}
                        
                        tr(v-if="$store.state.currentOrg.founding_date")
                            td()
                                label Founding date
                            td#founding_date().text-right
                                .small {{$store.state.currentOrg.founding_date}}
                template(v-if="currentOrg").mb-3
                   
                    table.table

                        tr()(v-if="currentOrg.relations &&  currentOrg.relations.acquisitions && currentOrg.relations.acquisitions.cards.acquirer_acquisitions && currentOrg.relations.acquisitions.cards.acquirer_acquisitions.length > 0")
                            td()
                                label Acquired by
                            td#employee_count().small.text-right
                                a(:href="'/ecosystem/orgs/' + currentOrg.relations.acquisitions.cards.acquirer_acquisitions[0].acquirer_identifier.uuid")
                                    | {{currentOrg.relations.acquisitions.cards.acquirer_acquisitions[0].short_description}}

                        tr()(v-if="currentOrg.total_funding_usd")
                            td()
                                label Total funding
                            td#employee_count().text-right
                                .small ${{currentOrg.total_funding_usd.toString().formatPrice()}}

                        tr()(v-if="currentOrg.last_funding_on")
                            td()
                                label Last funding on
                            td#employee_count().text-right
                                .small {{currentOrg.last_funding_on | moment("YYYY-MM")}}
                        

                    
                        tr()(v-if="currentOrg.stock_symbol")
                            td()
                                label Stock market
                            td#employee_count().text-right
                                .small {{currentOrg.stock_symbol}}

                        tr()(v-if="currentOrg.number_of_funding_rounds")
                            td()
                                label Number of funding rounds
                            td#employee_count().text-right
                                .small {{currentOrg.number_of_funding_rounds}}

                        tr()(v-if="currentOrg.last_funding_type")
                            td()
                                label Current stage
                            td#employee_count().text-right
                                .small {{currentOrg.last_funding_type}}
                        
                        tr()(v-if="currentOrg.index_space && currentOrg.index_space.startup_standard_survey && currentOrg.index_space.startup_standard_survey.custom_data")
                            td()
                                label AI 4 Good
                            td#employee_count().text-right
                                .small {{currentOrg.index_space.startup_standard_survey.custom_data.ai_4_good}}
                
                template(v-if="currentOrg && currentOrg.relations && currentOrg.relations.acquisitions && currentOrg.relations.acquisitions.cards.ipos && currentOrg.relations.acquisitions.cards.ipos.length > 0").mb-3
                   
                    table.table

                        tr()
                            td()
                                label IPO
                            td#employee_count().small.text-right
                                
                                | {{currentOrg.relations.acquisitions.cards.ipos[0].went_public_on}}, {{currentOrg.relations.acquisitions.cards.ipos[0].stock_full_symbol}}
                                div
                                    a(:href="'https://www.google.com/finance/quote/' + currentOrg.relations.acquisitions.cards.ipos[0].stock_full_symbol.split(':')[1] + ':' + currentOrg.relations.acquisitions.cards.ipos[0].stock_full_symbol.split(':')[0] ", target="_blank") Google Finance  

                     
                    <div class="tradingview-widget-container"  :id="container" ref="tradingview">
                        <div id="tradingview_2e38c"></div>
                        <div class="tradingview-widget-copyright"><a :href="'https://www.tradingview.com/symbols/' + currentOrg.relations.acquisitions.cards.ipos[0].stock_symbol" rel="noopener" target="_blank">By TradingView</a></div>
                       
                    </div>
                   
                    
        .col-12.col-lg-6

            div(style="max-width: 600px").mb-3
                div(v-if="$auth.user.instance == 'rox' || $auth.user.instance == 'roxdev'").mb-3
                    
                    div(v-if="!currentOrg.custom_data.freshworks_pushed")
                        b-btn(variant="outline-secondary", size="sm", @click="push_crm()") Push to CRM
                    div(v-else)
                        a(:href="currentOrg.custom_data.freshworks_url", target="_blank") Pushed to CRM 
                            i.fas.fa-external-link-square-alt.ml-1

                //b-card(v-if="links && ($auth.user.instance == 'rox' || $auth.user.instance == 'roxdev')")
                    div(v-if="links.external_links && links.app_links.length > 0").mt-1
                        b Apps
                        div(v-for="link in links.app_links")
                            a(:href="link").small {{link}}
                    
                    div(v-if="links.external_links && links.external_links.length > 0").mt-1
                        b External Links
                        div(v-for="link in links.external_links")
                            a(:href="link.url").small 
                                template(v-if="link.org")
                                    | {{link.org.name}} 
                                   
                                    template(v-for="gc in link.org.crunchbase_category_list")
                                        b-badge.mr-1 {{gc}} 
                                template(v-else)
                                    | {{link.web_entity}}


              

                template(v-if="instance_config.app_config.base.version == 'standard'")
                    .row.mb-5
                        .col(v-for="(v,k) in instance_config.organization_kpis_short").d-flex.justify-content-between.align-items-center
                            div.text-center
                                b(v-if="isNaN(currentOrg[k])") {{currentOrg[k]}}
                                b(v-else-if="currentOrg[k]") {{currentOrg[k].toString().formatPrice()}}
                                b(v-else) -
                                .small.text-muted {{v}}
                    
                    .row(v-if="currentOrg.custom_data.sbti")
                        .col-12
                        
                            b.mt-1 SBTI Details
                            .mb-5()
                                table.table.small
                                    tr(v-for="(v,k) in currentOrg.custom_data.sbti", v-if="k && v")
                                        td()
                                            label {{k}}
                                        td() {{v}}

                    .row(v-if="currentOrg.custom_data.cdp")
                        .col-12
                            b.mt-1 CDP Details
                            mb-5()
                                table.table.small
                                    tr(v-for="(v,k) in currentOrg.custom_data.cdp", v-if="k && k.length > 2 && v")
                                        td()
                                            label {{k}}
                                        td() {{v}}

                .row
                    .col-12
                        h5 Projects
                    
                        b-card(v-if="add_to_use_case").mt-1
                            .mt-3() 
                                    
                                    ProjectStageSelect(id="projectStageAdd" :exclude_project_ids="currentOrg.index_space.projects ? currentOrg.index_space.projects.map(x=>x._id) : []" v-model="selectedProjectStage", :create_option="false", placeholder="Select project").small
                                    .mt-1
                                    b-btn(variant="outline-primary", @click="addCompaniesToProject([currentOrg], selectedProjectStage)", size="sm", :disabled="!selectedProjectStage").form-control Add to project


                            .mt-3
                                b-card(v-for="pt in this.$_.sortBy(currentOrg.index_space.projects, ['name'])").mt-1
                                    .row
                                        .col-12
                                            .media
                                                    Logo(:name="pt.name", :size="40", :inline="true", :rounded="false").mr-1
                                                    .media-body 
                                                        a(target="_top", :href="'/projects/'+ pt._id.split('::')[0]")
                                                            | {{pt.name}}
                                                            .small.text-muted {{pt.stage && pt.stage != "_deleted_" ? pt.stage : "Not assigned"}}   
                                        .col-12.col-lg-6
                                            .mt-2
                                                b 
                                                    b-link(:to="'/topics/usecases/'+ pt._id.split('::')[1]") {{pt.topic_name}}                      
                                
                .row.mt-4(v-if="comments && comments.length > 0")
                    .col-6
                        h5 Last comment
                .row(v-if="comments && comments.length > 0")
                    .col-12
                        b-link(:href="'/ecosystem/orgs/' + currentOrg._id + '/contacts'")
                            b-card(v-if="comments && comments.length > 0").mt-1
                                .small {{comments.slice(0)[0].created_at | utcFrom}}
                            
                                User(:user="comments.slice(0)[0].relations.owner_id", :size="25").mr-2
                                | {{comments.slice(0)[0].text}}

                     
            
          
        
</template>

<script>
// @ is an alias to /src

//import Vue from 'vue'
import Config from '@/config'
import Comments from '@/components/Comments.vue'
import Contacts from '@/components/Contacts.vue'
import UseCaseInfo from '@/components/UseCaseInfo.vue'
import TaxonomyDisplay from '@/components/TaxonomyDisplay.vue'
import ProjectStageSelect from '@/components/ProjectStageSelect.vue'
import UseCaseSelect from '@/components/UseCaseSelect.vue'
import User from '@/components/User'

import Logo from '@/components/Logo.vue'

import Vue from 'vue'

import { mapFields } from 'vuex-map-fields';


const SCRIPT_ID = 'tradingview-widget-script';
const CONTAINER_ID = 'tradingview-widget-container';

export default {
  
  name: 'Profile',
  
  data: function () {
    return {
        add_to_use_case: true,
        selectedProjectStage: null,
        container: CONTAINER_ID,
        selectedUseCase: null,
        shrink: false,
        comments: null,
        read_more: false,
        funding_rounds: null,
        kws_debug: null,
        links: null,
        funding_rounds_fields: [
            {
                "key": "announced_on",
                "label": "Announced",
                formatter: function(x) {return x.substring(0,10)},
                sortable: true,
            },
             
             {
                "key": "name",
                "label": "Round"
            },
            {
                "key": "raised_amount_usd",
                "label": "Raised",
                "class": "text-right",
                formatter: function(x) { return   (x ? "$" + x.toString().formatPrice() : "undisclosed")}
            }
            

        ],
    }
  },

  mounted: function() {
    
    this.host = Config.config.base_host;
  
    if(!this.$route.query.extension) {
        /*Vue.http.post(`${Config.config.base_host}/service/links`, {"domain": this.currentOrg.web_entity}).then(res => {
                this.links = res.body;  

        });*/

         Vue.http
        .get(
          `${Config.config.base_host}/comments?${escape(
            'organization_id'
          )}=${escape(this.currentOrg._id)}`
        )
        .then(res => {
          this.comments = res.body;
        });
        
    }
     

   

    if(!this.$route.query.extension) {
        setTimeout(() => {

            if(this.currentOrg.relations.acquisitions && this.currentOrg.relations.acquisitions.cards.ipos) {
                const script = document.createElement('script');
                script.id = SCRIPT_ID;
                script.type = 'text/javascript';
                script.async = true;
                script.src = 'https://s3.tradingview.com/tv.js';

                script.onload = onload;

                const script2 = document.createElement('script');
                script2.type = 'text/javascript';
                script2.textContent = `new TradingView.MediumWidget(
                    {
                    "symbols": [
                        [
                        "` + this.currentOrg.name + `",
                        "`+ this.currentOrg.relations.acquisitions.cards.ipos[0].stock_full_symbol.replace("ETR", "XETR") + `"
                        ]
                    ],
                    "chartOnly": false,
                    "width": "100%",
                    "height": 400,
                    "locale": "en",
                    "colorTheme": "light",
                    "gridLineColor": "#495057",
                    "trendLineColor": "#2962FF",
                    "fontColor": "#787B86",
                    "underLineColor": "rgba(212, 223, 232, 0.5)",
                    "underLineBottomColor": "rgba(212, 223, 232, 0.3)",
                    "isTransparent": false,
                    "autosize": true,
                    "container_id": "tradingview_2e38c"
                    }
                    )`;
                
                this.$refs.tradingview.appendChild(script);
                setTimeout(() => {
                    this.$refs.tradingview.appendChild(script2);
                }, 1000);
                
            }
            

        }, 1000);
    }
   

  },

   computed: {

        ...mapFields([
            
            'currentOrg',
            'instance_config'
        ]),
  
  },

  methods: {
    get_youtube_id(video) {
        try {
             var video_id = video.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if(ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            return video_id;
        } catch {
            return null;
        }

       
    },

    push_crm() {
        Vue.http
        .post(Config.config.base_host + "/organizations/" +  this.currentOrg._id + "/crm_export")
        .then(resp => {
          console.log(resp.body);
          if(resp.body.errors) {
            this.$bvToast.toast(resp.body.errors.message, {
              title: 'Error',
              variant: 'alert',
              autoHideDelay: 1000,
          });
          } else {
             this.currentOrg.custom_data.freshworks_pushed = true;
             Vue.set(this.currentOrg.custom_data, "freshworks_pushed", true);
             this.$store.dispatch('loadOrg', {"org_id": this.currentOrg._id});
             this.$forceUpdate();
             this.$bvToast.toast("Account pushed", {
              title: 'Account pushed',
              variant: 'outline-primary',
              autoHideDelay: 1000,
          });

          }
         
      
        });
    },


    addCompaniesToProject(selectedItem, selectedProjectStage) {
      let items = selectedItem.map(x => {
        return { organization_id: x._id, project_id: selectedProjectStage };
      });

      this.$store.dispatch("start_loading");
      Vue.http
        .post(Config.config.base_host + "/project_organizations/bulk", items)
        .then(resp => {
          
          let data = resp.body;
          console.log(data);
          setTimeout(()=>{
                this.$store.dispatch("loadOrg", {org_id: this.$route.params.org_id, web_entity: this.$route.query.web_entity});
                this.$store.dispatch("stop_loading");
          }, 1000)
      
        });
    },

    do_add_to_usecase(selectedProjectStage, selectedUseCase) {
       

        Vue.http.post(`${Config.config.base_host}/topics_v1/${selectedUseCase}/projects/${selectedProjectStage}/org/bulk`, {use_case_id: selectedUseCase, organization_ids: [this.$route.params.org_id]}).then(() => {
            this.$store.dispatch("loadOrg", {org_id: this.$route.params.org_id, web_entity: this.$route.query.web_entity});
            this.add_to_use_case = false;
        });

    },


    getData(tag) {
        
       Vue.http.post(`${Config.config.base_host}/service/keyword_detect/debug`, {"domain": this.currentOrg.web_entity, "tags": [tag]}).then(res => {
            this.kws_debug = res.body;
      });

    },


  },
  components: {
      Comments,
      Contacts,
      TaxonomyDisplay,
      UseCaseInfo,
      UseCaseSelect,
      ProjectStageSelect,
      Logo,
      User
  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>