<template lang="pug">
    .main
      
      .container-fluid
        .row.mt-5
          .col-lg-2
          
          
          .col-lg-4.col-md-12(style="max-width: 600px")
          
              .row
                .col-12
                  div(class="spinner", v-if="busy && !stop_loading").text-center.d-block.mx-auto
                    div(class="double-bounce1")
                    div(class="double-bounce2")
          
       
</template>   

<script>

import Config from '@/config'
import Vue from 'vue'

export default {
  name: 'Main',

  data () {
    return {
     
      stop_loading: false,
      busy: false,
      loaded: false
    }
  },

  mounted() {
    
      this.busy = true;
      this.$store.dispatch("start_loading");
      
      if(this.$route.params.domain) {
      
        let domain = this.$route.params.domain.replace("www.", "").replace("https://", "").replace("http://", "");
        
        return Vue.http.get(Config.config.base_host+'/ecosystem/lookup/' + domain).then((resp) => { 
            if(resp.body.organization_id) {
                this.$store.dispatch("stop_loading");
                location.href = '/ecosystem/orgs/' + resp.body.organization_id + '?'+ (this.$route.query.source == "news" ? "e=0" : "extension=1") +'&web_entity=' + domain;
            } else {
                this.$store.dispatch("stop_loading");
                location.href = '/ecosystem/orgs/new?extension='+(this.$route.query.source == "news" ? "e=0" : "extension=1")+'&web_entity=' + domain ;
            }
            
        });
       
      }

  },

  methods: {

  },

  components: {
  
  }
}
</script>


<style scoped lang="scss">
@import '~@/_vars.scss';


</style>
