<template lang="pug">
  
  .home(v-if="this.$store.state.currentOrg")
    .row
        .col-0.col-lg-2
          | &nbsp;
        .col-12.col-lg-8 


            template(v-if="sims")
              b-card().mb-2
                .row
                  .col-3
                    b-form-group(label="Sort by")
                      b-select(v-model="sortBy", :options="sortOptions" size="sm") Sort by
                  .col-3
                    b-form-group(label="Filter")
                      b-dropdown#dropdown-form.mr-2(text='Advanced filter' ref='dropdown', variant="outline-secondary", size="sm", bottom, @click="hideCheck")
                            template(#button-content)
                            
                              span(style="text-transform: none") Advanced filter 

                            b-dropdown-form(style="min-width: 800px")
                              
                              SearchFilterHealth(@click.native="handleClick", @search="getSims()").mr-3
                  .col-6
                    .float-right
                      b-form-group(label="Filter")
                        b-check(v-model="funding_only") Show only companies with funding
              b-card(:header="'Companies similar to ' + this.$store.state.currentOrg.name" no-body, v-if="sims").shadow-sm
              
                b-list-group  
                  b-list-group-item( v-for="(item, index) in $_.orderBy(sims.rows, [sortBy, 'relations.sim'], [sortBy == 'relations.sim' ? 'desc' : 'desc', 'asc'])" v-if="item.web_entity != $store.state.currentOrg.web_entity && index < 400 && (!funding_only || item.total_funding_usd > 0)")
                    
                    .media()
                        router-link(:to="'/ecosystem/orgs/'+item._id")
                          .feed-image-small(:style="'background: url(https://images.hytechnologies.co/unsafe/80x80/logo.clearbit.com/' + item.web_entity + ') center 0% / cover no-repeat;'").mr-3
                        .media-body
                          router-link(:to="'/ecosystem/orgs/'+item._id")
                            h5 {{item.name}}
                          .small.text-muted {{item.short_description}} 
                          .small <a :href="'http://'+item.web_entity">{{item.web_entity}}</a>
                          .small(v-if="item.total_funding_usd_inner") Total funding: {{"$" + item.total_funding_usd.toString().formatPriceMillion() }}
                          .small(v-if="item.employee_count_inner") Number of Employees: {{item.employee_count}} 
                          //.small {{item.relations.sim}}
                          .row
                            .col-12
                              div(v-if="item.custom_data['industry'] && item.custom_data['industry'].length")
                                  label().mt-2 Industry
                                  TaxonomyDisplay(topic_type="industry", :topic_ids="item.custom_data['industry']")
    
                              div(v-if="item.custom_data['enterprise_function'] && item.custom_data['enterprise_function'].length")
                                  label().mt-2 Enterprise Function
                                  TaxonomyDisplay(topic_type="enterprise_function", :topic_ids="item.custom_data['enterprise_function']")

                              div(v-if="item.custom_data['vertical'] && item.custom_data['vertical'].length")
                                  label().mt-2 Verticals
                                  TaxonomyDisplay(topic_type="vertical", :topic_ids="item.custom_data['vertical']")

                              div(v-if="item.custom_data['sub_vertical'] && item.custom_data['sub_vertical'].length")
                                  label().mt-2 Sub-Vertical
                                
                                  TaxonomyDisplay(topic_type="sub_vertical", :topic_ids="item.custom_data['sub_vertical']")

                              div(v-if="item.custom_data['field_of_expertise'] && item.custom_data['field_of_expertise'].length > 0 && item.custom_data['field_of_expertise'][0]")
                                  label().mt-2 Fields of expertise
                                  div
                                      b-badge(variant="light").mr-1(v-for="field in item.custom_data.field_of_expertise") {{field}}

                          .row
                              
                                b-card(v-for="pt in item.index_space.project_topics").col-6.mt-1
                                    .row
                                        .col-12
                                            .media
                                                    Logo(:name="pt.project_name", :size="40", :inline="true", :rounded="false").mr-1
                                                    .media-body 
                                                        b-link(:to="'/projects/'+ pt._id.split('::')[0]")
                                                            | {{pt.project_name}}
                                                            .small.text-muted {{pt.group && pt.group != "_deleted_" ? pt.group : "Not assigned"}}   
                                    .mt-2
                                        b 
                                            b-link(:to="'/topics/usecases/'+ pt._id.split('::')[1]") {{pt.topic_name}}                      
                                    UseCaseInfo(topic_type="use_case", :topic_ids="[pt._id.split('::')[1]]").mt-2
                                    
                            
          
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'
import TaxonomyDisplay from '@/components/TaxonomyDisplay'
import Logo from '@/components/Logo'
import UseCaseInfo from '@/components/UseCaseInfo'
import SearchFilterHealth from '@/views/orgs/SearchFilterHealth'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Similar',
  
  data: function () {
    return {
        sims: {},
        sortBy: 'relations.sim',
        sortOptions: [{"text": "Similarity", "value": "relations.sim"}, {"text": "Funding", "value": "total_funding_usd_inner"},  {"text": "Number of employees", "value": "employee_count_inner"}],
        funding_only: false
    }
  },

  mounted: function() {
  
    this.host = Config.config.base_host;
    setTimeout(()=>{
      this.getSims();
    }, 200);
    
    
  },


   computed: {

        ...mapFields([
            'query.keywords',
            'query.filter_sim'
        ]),
  },

  methods: {

    handleClick(ev) {
    
      ev.stopPropagation();
    },

    
    getSims() { 
      this.$store.dispatch("start_loading");

      this.filter_sim.limit = 200;
      this.filter_sim.sources = ['hy'];
      this.filter_sim.sim_search =  [{"web_entity": "Text: " + this.$store.state.currentOrg.short_description +  " " + (this.$store.state.currentOrg.description ? this.$store.state.currentOrg.description : "") + " "  + (this.$store.state.currentOrg.web_text ? this.$store.state.currentOrg.web_text : ""), "name": this.$store.state.currentOrg.name}];

      Vue.http.post(`${Config.config.base_host}/organizations/search?similar=1`, {"filter": this.filter_sim}).then(res => {
        this.sims = res.body;
       
        this.sims.rows.forEach((item, k) => {
         
          if(item.employee_count == "unknown") {
          
     
            this.sims.rows[k].employee_count_inner = 0;
          } else {
            this.sims.rows[k].employee_count_inner = parseInt(this.sims.rows[k].employee_count.split("-")[0]);
          }

          if(item.total_funding_usd == "unknown" || !item.total_funding_usd ) {
          
           
            this.sims.rows[k].total_funding_usd_inner = 0;
          } else {
            this.sims.rows[k].total_funding_usd_inner = parseFloat(this.sims.rows[k].total_funding_usd);
          }

        });
        this.$store.dispatch("stop_loading");
      });
    }


  },
  components: {
    TaxonomyDisplay,
    UseCaseInfo,
    Logo,
    SearchFilterHealth
  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>