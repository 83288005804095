<template lang="pug">
  
  .home(v-if="currentOrg")
   
    .row
      .col-md-3

        .twitter-desc(v-if="currentOrg.twitter_url")
          a(class="twitter-timeline" :href="currentOrg.twitter_url") 
        
      .col-md-8()

       
        //.row
            .col-12
                .float-right
                    b-select(:options="sort_options", v-model="sortBy", styl  e="max-width: 150px", size="sm",  @change="getFeed()")
        .row
            .col-12
                div(v-if="results && results.length == 0 && !$store.state.loading && oneloaded").text-muted No results found. Try <a :href="news_link" target="_blank">Google News Search</a>.
                b-overlay(:show="$store.state.loading")
                    
                    b-list-group(id="news-list-group").mt-3
                    
                        b-list-group-item(v-if="results && results.length > 0" v-for="entry in results")
                          .media 
                            a(:href="entry.url", target="_blank")
                                div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.source + ') center 0% / cover no-repeat;'").mr-2
                               
                            .media-body(style="max-width: 800px")
                                a(:href="entry.url", target="_blank")
                                    h6.mb-1 {{entry.title}}
                                    .text-muted.small {{entry.pub_date | utcFrom}} &dash; {{entry.source}} 
                                    span.mr-1
                                      .badge.badge-secondary(v-if="entry.categories && entry.categories['INVESTMENT'] > 0.5") Investment
                                      .badge.badge-secondary(v-if="entry.categories && entry.categories['PARTNER'] > 0.5") Partner
        .row
          .col-12
            infinite-loading(@infinite="getFeed")
              div(slot="spinner").mt-4 Loading...
              div(slot="no-more") 
              div(slot="no-results") No results found for your query.
                                  
                               
                      
                
                        
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
import NewsFilter from '@/views/news/NewsFilter'
import OrgView from '@/components/OrgView.vue'
import InfiniteLoading from 'vue-infinite-loading';

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'home',
  
  data: function () {
    return {
        feed: [],
        busy: true,
        sort_options: [{"text": "Date", "value": "pub_date"}, {"text": "Relevance", "value": "_score"}],
        newsletter: {"items": []},
        results: [],
        page: 1,
        oneloaded: false
    }
  },

  mounted: function() {
  
    this.host = Config.config.base_host;
    
    this.$store.getters.news.results = []; 
    try {
      document.getElementById("twitter-wjs").remove();
    } catch(e) {
      // 
    }
    window.init_twitter();
    //this.getNewsletter();
    
    //this.getFeed();

    

  },

   computed: {

     news_link: function() {
      return 'https://www.google.com/search?q="' + this.currentOrg.name + '"&tbm=nws';
     },

        ...mapFields([
            'currentOrg'
        ]),
  },

  methods: {

    in_newsletter(entry) {
      
      return this.newsletter.items.map((it)=>{return it.link}).indexOf(entry.link) > -1;
    },

    remove_newsletter(entry) {
      this.newsletter.items = this.newsletter.items.filter(it => it.link != entry.link);
      this.save_newsletter(this.newsletter);
    },

    add_newsletter(entry) {
      this.newsletter.items.push(entry);
      this.save_newsletter(this.newsletter);
    },

    save_newsletter(nl) {

      if(!nl) {
        nl = {"_id":  "newsletter:" + this.$store.state.user.id};
      }

      Vue.http.put(`${Config.config.base_host}/newsletters/${nl._id}`, nl).then((res) => {   
        this.newsletter = res.body;
      });

    },

    getNewsletter(nl_id = null) {
        if(!nl_id) nl_id = "newsletter:" + this.$store.state.user.id;
        this.$store.dispatch('start_loading');
        Vue.http.get(`${Config.config.base_host}/newsletters/${nl_id}`).then((res) => {
            this.newsletter = res.body;
            this.$store.dispatch('stop_loading');
      });
    },
    
    reset() {
       if(this.$refs.inload){ 
          this.$refs.inload.stateChanger.reset(); 
      }
    },

    getFeed(state) {
              
        console.log(state);
        this.$store.state.loading = true;
        Vue.http.get(`${Config.config.base_host}/organizations/${this.currentOrg._id}/news/${this.page}`).then(res => {

            if(res.body.length > 0) {
              res.body.forEach((x) => {
                this.results.push(x);  
              });
              this.page++;
             
            } else {
              state.complete()
            }

            if(res.body.length == 1 && this.page == 1) {
              this.oneloaded = true;
              state.complete()
            }

            state.loaded()
            this.oneloaded = true;

            this.$store.state.loading = false;
          
           
            
        }); 
    
    }

  },
  components: {
    OrgView,
    NewsFilter,
    InfiniteLoading
  }
}

</script>
<style lang="scss" scoped>

.twitter-desc {
  max-height: 600px;
  overflow-y: scroll;
}
</style>