<template lang="pug">
  
  .home(v-if="this.$store.state.currentOrg && this.$store.state.currentOrg.web_entity")
    .row
        b-embed(
            type="iframe"
            aspect="16by9"
            :src="'https://' + this.$store.state.currentOrg.web_entity")
           

        
          
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'
import TaxonomyDisplay from '@/components/TaxonomyDisplay'
import UseCaseInfo from '@/components/UseCaseInfo'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Similar',
  
  data: function () {
    return {
        sims: {}
    }
  },

  mounted: function() {
  
    this.host = Config.config.base_host;
   
    
  },


   computed: {

        ...mapFields([
            'query.keywords'
        ]),
  },

  methods: {
    
    getSims() { 
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/organizations/search?similar=1`, {"filter": {"sources": ["ecosystem","hy"], "sim_search": [this.$store.state.currentOrg]}}).then(res => {
        this.sims = res.body;

        this.$store.dispatch("stop_loading");
      });
    }


  },
  components: {
    TaxonomyDisplay,
    UseCaseInfo
  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>