<template lang="pug">
  .filter-group
  
    v-select(:taggable="false", :multiple="multiple" v-model="internal_value", :placeholder="placeholder", label="web_entity", :close-on-select="false", :options="options", :filterable="false", @search="onSearch")
        
        template(slot="no-options") Type to search
        template(slot="option" slot-scope="option")

      
            template(v-if="!option.sim")
               
                .media
                    Logo(:web_entity="option.web_entity", :name="option.name", :size="15").mr-1
                    .media-body
                      | {{ option.web_entity }}
            template(v-if="option.sim")
                    | Text: "{{option.name.substring(0,25)}} <span v-if="option.name.length>25"> ...</span>"
        
        template(slot="selected-option" slot-scope="option")
            .selected
                | {{ option.web_entity }}

</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Logo from '@/components/Logo'
export default {
  name: 'CompanyTextSelect',
  
  props: {
    "value":  {
      default: null
    }, 

    "placeholder":  {
      default: ""
    }, 

    "multiple": {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
        uid: null,
        options: [],
        prev_request: null,
        internal_value: null
        
    }
  },

  mounted: function() {
    this.internal_value = this.value;
    this.uid = this._uid; 
  },

  watch: {
    value: function() {
     
     this.internal_value = this.value;
  
    },
    internal_value: function(val) {
     
      this.$emit("input", val);
  
    }
  },
  
  methods: {
    
   onSearch(search, loading) {
      
      this.$emit("search", search);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
      
      
      if(search) {
        loading(true);
        let new_options = [];
        if(search.indexOf(" ") > -1) {
            new_options.push({"web_entity": "Text: " +search, "name": search, "sim": true});
            vm.options = new_options;
            loading(false);
        } else {
            console.log(vm.prev_request);
        
            vm.prev_request = Vue.http.get(`${Config.config.base_host}/organizations/search/autocomplete?q=${escape(search)}`).then(res => {
            
            new_options = res.body.rows.filter(x => x.web_entity);
            new_options.push({"web_entity": "Text: " +search, "name": search, "sim": true});
            vm.options = new_options;
            

            loading(false);
            });
        }
      } else {
        vm.options = [];
      }

    }, 50)
  },

  components: {
    Logo
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
