<template lang="pug">
  
  .financials-container
     
    .investments-overview(v-if="org_details && org_details._id").mt-2
      b-card
        h6 Investments Overview
        .row.pt-4
          .col-6.col-lg-3.kpi.mb-3
            h3( v-if="total_funding_usd" ) $ {{total_funding_usd.toString().formatPrice()}}
            h3( v-else )  N.A.
            .small.pt-4l Total funding
          .col-6.col-lg-3.kpi.mb-3
            h3(v-if="org_details.use_internal_funding_data" ) {{self_investors.length || "N.A."}}
            h3(v-else-if="!org_details.use_internal_funding_data" ) {{investors.length || "N.A."}}
            .small.pt-4l Investors
          .col-6.col-lg-3.kpi.mb-3
            h3(v-if="funding_rounds && funding_rounds.length")  {{funding_rounds.length}}
            h3(v-else ) N.A.
            .small.pt-4l Funding rounds recieved
          .col-6.col-lg-3.mb-3
            h3(v-if="funding_rounds_made && funding_rounds_made.length")  {{funding_rounds_made.length}}
            h3(v-else ) N.A.
            .small Funding rounds participations as investor
        
        .row.pt-4(v-if="currentOrg.relations &&  currentOrg.relations.acquisitions && currentOrg.relations.acquisitions.cards.acquirer_acquisitions && currentOrg.relations.acquisitions.cards.acquirer_acquisitions.length > 0")
            .col-12
                a(:href="'/ecosystem/orgs/' + currentOrg.relations.acquisitions.cards.acquirer_acquisitions[0].acquirer_identifier.uuid")
                    | {{currentOrg.relations.acquisitions.cards.acquirer_acquisitions[0].short_description}}

                    
    .row.mt-5
      .col-12.col-lg-6       
        b-card(v-if="funding_rounds && funding_rounds.length")
          h6.mb-2 Funding rounds
          .investors-holder
            b-table.small(:items="funding_rounds", :fields="funding_rounds_fields", size="sm")
              template(v-slot:cell(announced_on)="row")
                b-link(:href="row.item.cb_url", target="_blank") {{row.item.announced_on | moment("DD.MM.YYYY")}}
              template(v-slot:cell(name)="row")
                b(v-if="row.item.name") {{row.item.name}}
                b(v-if="!row.item.name && row.item.investment_type") {{row.item.investment_type.replace("_", " ")}}
                  .d-block
                    b-link(:disabled="!$auth.has_access('organization')", v-for="inv in row.item.relations.investors_organization_cb_uuids", :href="'/ecosystem/orgs/'+inv._id").text-gray.d-inline-block.mr-1 {{inv.name}},
                    .text-gray.d-inline-block.mr-1( v-if="row.item.investors_organization_names", v-for="inv in row.item.investors_organization_names" ) {{inv}},

      .col-12.col-lg-6                  
        b-card(v-if="investors && investors.length")
          h6.mb-2 Investors
          .investors-holder
            table().table( v-if="org_details.use_internal_funding_data" )
              tr(v-for="investor_obj in self_investors")
                td()
                  template(v-if="investor_obj.investor._id")
                    b-link.small(:to="'/ecosystem/orgs/' + investor_obj.investor._id")
                      b {{investor_obj.investor.name}}
                  template(v-else)
                    b.small {{investor_obj.investor.name}}
                td() 
                  .d-inline-block
                    .small.text-gray.mr-1 {{investor_obj.round}}

            table().table( v-else )
              tr(v-for="investor in investors")
                td()
                  template(v-if="investor.organization_id")
                    b-link.small(:to="'/ecosystem/orgs/' + investor.organization_id")
                      b {{investor.name}}
                  template(v-else)
                    b.small {{investor.name}}
                td() 
                  .d-inline-block(v-for="inv in investor.investments")
                    b-link.small(:href="inv.cb_url", target="_blank").text-gray.mr-1 {{inv.name.split(" in ")[1]}} <span v-if="inv.is_lead_investor">(Lead)</span>, 
      .col-12.col-lg-6
        b-card(v-if="funding_rounds_made && funding_rounds_made.length").mt-4
          h6 Funding rounds partipated as investor
          .investors-holder
            b-table.small(:items="funding_rounds_made", :fields="funding_rounds_fields", size="sm")
              template(v-slot:cell(announced_on)="row")
                b-link(:href="row.item.cb_url", target="_blank") {{row.item.announced_on | moment("DD.MM.YYYY")}}    
              template(v-slot:cell(name)="row")       
                b-link(:href="'/ecosystem/orgs/'+row.item.organization_id").d-block {{row.item.name}} 
        b-card(v-if="!funding_rounds_made || !funding_rounds_made.length").mt-4
          h6 Funding rounds partipated as investor
          .investors-holder
            p.text-center.text-gray.mt-4
              | No funding rounds participated as investor
      
      .col-12.col-lg-6(v-if="acquisitions.acquisitions && acquisitions.acquisitions.length > 0")
        b-card().mt-4
          h6 Acquistions
          .investors-holder
           
            b-table.small(:items="acquisitions.acquisitions", :fields="acquisitions_fields", size="sm")
              template(v-slot:cell(announced_on)="row")
                b-link(:href="row.item.cb_url", target="_blank") {{row.item.announced_on | moment("DD.MM.YYYY")}}    
              template(v-slot:cell(name)="row")       
                b-link(:href="'/ecosystem/orgs/'+row.item.org._id").d-block {{row.item.org.name}} 
    
    
          
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Financials',
  data: function () {
    return {
      sims: {},
      investors: [],
      self_investors: [],
      org_details: {},
      total_funding_usd: 0,
      acquisitions: [],
      funding_rounds_made: [],
      funding_rounds: null,
      funding_rounds_fields: [
          {
              "key": "announced_on",
              "label": "Announced",
              formatter: function(x) {return x ? x.substring(0,10) : null},
              sortable: true,
          },
          {
              "key": "name",
              "label": "Round"
          },
          {
              "key": "raised_amount_usd",
              "label": "Raised",
              "class": "text-right",
              formatter: function(x) { return   (x ? "$" + x.toString().formatPrice() : "undisclosed")}
          }
      ],
       acquisitions_fields: [
          {
              "key": "acquired_on",
              "label": "Announced",
              formatter: function(x) {return x ? x.substring(0,10) : null},
              sortable: true,
          },
          {
              "key": "name",
              "label": "Target"
          },
          {
              "key": "price_usd",
              "label": "Price ($US)",
              "class": "text-right",
              formatter: function(x) { return   (x ? "$" + x.toString().formatPrice() : "undisclosed")}
          }
      ],
    }
  },
  mounted: function() {
    this.host = Config.config.base_host;
    this.getData();  
  },
  computed: {
    ...mapFields([
        'query.keywords',
        'currentOrg'
    ]),
  },
  methods: {
    getData() { 
      this.org_details = this.$_.cloneDeep( this.currentOrg );
      this.$store.dispatch('start_loading'); 
      Vue.http.get(`${Config.config.base_host}/organizations/${this.$route.params.org_id}/funding_rounds`).then(res => {
            this.funding_rounds     = res.body;  
            this.self_investors     = [];
            this.total_funding_usd  = 0;


            this.funding_rounds = this.$_.reverse(this.$_.sortBy(this.funding_rounds, ['announced_on']) );

            if( this.org_details.use_internal_funding_data && this.funding_rounds.length && this.funding_rounds.length > 0 ){
               this.funding_rounds.forEach( (funding_round  )=>{ 
                 this.total_funding_usd += funding_round.raised_amount_usd;
                 if(funding_round.relations && funding_round.relations.investors_organization_cb_uuids ){
                   this.self_investors.push( ...funding_round.relations.investors_organization_cb_uuids.map( ( investor )=>{
                                                  return {
                                                    investor,
                                                    round: funding_round.investment_type
                                                  };
                                              }) );
                 }

                 if(funding_round.investors_organization_names ){
                   this.self_investors.push( ...funding_round.investors_organization_names.map( ( investor_name )=>{
                                                  return {
                                                    investor: { name: investor_name },
                                                    round: funding_round.investment_type
                                                  };
                                              }) );
                 }
               })
            }
            this.$store.dispatch("stop_loading");
      });
      Vue.http.get(`${Config.config.base_host}/organizations/${this.$route.params.org_id}/funding_rounds_made`).then(res => {
            this.funding_rounds_made = res.body;  
            this.$store.dispatch("stop_loading");
      });
      Vue.http.get(`${Config.config.base_host}/organizations/${this.$route.params.org_id}/investors`).then(res => {
            this.investors = res.body;  
            this.$store.dispatch("stop_loading");
      });

      Vue.http.get(`${Config.config.base_host}/organizations/${this.$route.params.org_id}/acquisitions`).then(res => {
            this.acquisitions = res.body;  
            this.$store.dispatch("stop_loading");
      });
    },
  }
}

</script>
<style lang="scss" scoped>
  @import '@/_vars.scss';
  #gcontainer {
    height: 500px;
    width: 100%;
  }
  .green{
    color: $green;
  }
  .red{
    color: $red;
  }

  .feed-image {
    height: 50px;
    width: 50px;
  }
  .investors-holder {
    max-height: 300px;
    overflow: auto;
  }
  .table td {
    padding: 0.2em;
  }
  .kpi{
    border-right: 1px solid #4b4b4a;
  }

</style>